import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EventFormStore } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import TabWithHeader from 'site/pages/shared/events-and-requests/tab-with-header';
import EventFormHeader from '../header/event-form-header';
import RequestDetailsTabPane from './request-details/request-details-tab-pane';
import StudentsAssignTableComponentContainer from './students/students-tab-pane-container';
import CommentsTabPane from './comments/comments-tab-pane';
import SupportDetailsContainer from './support-details/support-details-tab-pane';
import InstructorsTabPane from './instructors/instructors-tab-pane';
import EventDetailsTabPane from './event-details/event-details-tab-pane';
import RequestsTabPane from './requests/requests-tab-pane';
import ValidationTabPane from './validations/validation-tab-pane';
import DocumentationTabPane from './documentation/documentation-tab-pane';
import EventWarnings from '../event-warnings';
import CheckListTabPane from './checklist/check-list-tab-pane';
import { IdentityService } from 'services/identity-service';
import { resolve } from 'inversify-react';
import { EventFormMode, AdditionalTabs } from '../event-form';
import FeedbackTab from './feedback/feedback-tab';
import TheoreticalTab from './theoretical/theoretical-tab';
import PracticalTab from './practical/practical-tab';
import { EventDto } from 'stores/events/events-store';

export interface EventFormTabMenuProps extends WithTranslation {
  onClose: (isSuccess: boolean) => void;
  mode: EventFormMode;
  readOnly: boolean;
  readOnlyForPocs: boolean;
  eventFormStore?: EventFormStore;
  isOnlySupportDetailsEnabled: boolean;
  additionalTabs?: AdditionalTabs;
  toLoadingState?: (boolean: boolean) => void;
  selectedEvent?: EventDto;
  fromRequestTab: boolean;
}

interface EventFormTabMenuState {
  loadTheoreticals: boolean;
  loadPracticals: boolean;
  keepOpenedInstructorTab: boolean;
}

@connect(['eventFormStore', EventFormStore])
class EventFormTabMenu extends Component<EventFormTabMenuProps, EventFormTabMenuState> {
  state: EventFormTabMenuState = { loadTheoreticals: false, loadPracticals: false, keepOpenedInstructorTab: false };
  @resolve(IdentityService)
  private identityService: IdentityService;

  private isTabReadOnly = () => {
    const { readOnly } = this.props;
    return readOnly;
  };

  private checkInstructorsTabPaneAvailability = (): boolean => {
    const { keepOpenedInstructorTab } = this.state;
    const { eventFormStore } = this.props;
    const { hasSupportDetails, supportDetails, hasInstructors, instructors } = eventFormStore.state.item;

    const hasSupportPositions: boolean =
      supportDetails && ((supportDetails.newSupportPositions || []).length > 0 || (supportDetails.supportPositions || []).length > 0);

    const eventNewModelPositions = (supportDetails.newSupportPositions || []).selectMany(x => x.eventNewModelPositions).toArray();
    const eventPositions = (supportDetails.supportPositions || []).selectMany(x => x.eventPositions).toArray();

    const hasEventPositions: boolean =
      hasSupportPositions && ((eventNewModelPositions || []).length > 0 || (eventPositions || []).length > 0);

    const newSupportPositionsWithEventNewModelPositions = (supportDetails.newSupportPositions || [])
      .filter(x => (x.eventNewModelPositions || []).length <= 0)
      .map(x => x.id);

    const supportPositionsWithEventPositions = (supportDetails.supportPositions || [])
      .filter(x => (x.eventPositions || []).length <= 0)
      .map(x => x.id);

    const mergedSupportPositions: string[] = [...newSupportPositionsWithEventNewModelPositions, ...supportPositionsWithEventPositions];

    const instructorTabPaneAvailable: boolean =
      keepOpenedInstructorTab ||
      (hasInstructors && !hasSupportDetails) ||
      (hasInstructors && hasSupportDetails && !hasEventPositions && (instructors || []).length > 0) ||
      (hasInstructors &&
        hasSupportDetails &&
        hasEventPositions &&
        (instructors || []).length > 0 &&
        (instructors.any(x => x.supportPositionId == null) || instructors.any(x => mergedSupportPositions.includes(x.supportPositionId))));
    return instructorTabPaneAvailable;
  };

  loadingTheoreticals = false;
  loadingPracticals = false;

  private buildPanes = () => {
    const { t, readOnly, readOnlyForPocs, mode, isOnlySupportDetailsEnabled, eventFormStore, additionalTabs, fromRequestTab } = this.props;
    const { hasRequestDetails, hasEventDetails, hasSupportDetails, hasStudents, hasRequests, supportDetails } = eventFormStore.state.item;
    let panes = [];
    const isReadOnly = this.isTabReadOnly();

    if (hasRequestDetails && (!hasSupportDetails || (supportDetails && !supportDetails.isNewSupportPositionModel)))
      panes.push({
        menuItem: t('Event Details'),
        render: () => <RequestDetailsTabPane mode={mode} readOnly={isOnlySupportDetailsEnabled || isReadOnly} loading={false} />
      });

    if (hasEventDetails)
      panes.push({
        menuItem: t('Training Details'),
        render: () => <EventDetailsTabPane readOnly={isOnlySupportDetailsEnabled || isReadOnly} loading={false} />
      });

    if (hasSupportDetails)
      panes.push({
        menuItem: t('Support Details'),
        render: () => <SupportDetailsContainer readOnly={isReadOnly} isHideEditButton={isOnlySupportDetailsEnabled} />
      });

    if (hasStudents && (!hasSupportDetails || (supportDetails && !supportDetails.isNewSupportPositionModel)))
      panes.push({
        menuItem: t('Students'),
        render: () => <StudentsAssignTableComponentContainer readOnly={readOnlyForPocs && readOnly} />
      });

    if (this.checkInstructorsTabPaneAvailability())
      panes.push({
        menuItem: t('Instructors'),
        render: () => (
          <InstructorsTabPane
            onClose={() => {
              if (this.props.onClose) this.props.onClose(false);
            }}
            onKeepOpen={onKeepOpen => {
              this.setState({ keepOpenedInstructorTab: true });
            }}
            isTabReadonly={isOnlySupportDetailsEnabled || isReadOnly}
            readOnly={readOnly}
            selectedEvent={this.props.selectedEvent}
          />
        )
      });

    if (hasRequests && !fromRequestTab)
      panes.push({
        menuItem: t('Requests'),
        render: () => <RequestsTabPane readOnly={isOnlySupportDetailsEnabled || isReadOnly} fromEventTab={true} />
      });

    if (mode === 'ViewDetails') {
      const event = { ...this.props.eventFormStore.state.item };
      const eventStatus = event.status.toString();

      let rolesRequiredToValidateStatus = null;

      switch (eventStatus) {
        case 'Planned':
          rolesRequiredToValidateStatus = event.eventType.planned.rolesRequiredToValidateStatus;
          break;
        case 'Completed':
          rolesRequiredToValidateStatus = event.eventType.completed.rolesRequiredToValidateStatus;
          break;
        case 'InProgress':
          rolesRequiredToValidateStatus = event.eventType.inProgress.rolesRequiredToValidateStatus;
          break;
        case 'Draft':
          rolesRequiredToValidateStatus = event.eventType.draft.rolesRequiredToValidateStatus;
          break;
        case 'Closed':
        default:
          rolesRequiredToValidateStatus = null;
      }

      if ((rolesRequiredToValidateStatus || []).length > 0) {
        panes.push({ menuItem: t('Validations'), render: () => <ValidationTabPane /> });
      }
    }

    panes.push({ menuItem: t('Documentation'), render: () => <DocumentationTabPane readOnly={readOnly} /> });

    panes.push({
      menuItem: t('Comments'),
      render: () => <CommentsTabPane readOnly={isOnlySupportDetailsEnabled || isReadOnly} loading={false} />
    });

    if (this.isCheckListTabVisible())
      panes.push({
        menuItem: t('CheckList'),
        render: () => <CheckListTabPane readOnly={isOnlySupportDetailsEnabled || isReadOnly} />
      });

    if (!this.isFeedbackTabInVisible()) panes.push({ menuItem: t('Feedback'), render: () => <FeedbackTab /> });

    if (additionalTabs.theoretical) panes.push({ menuItem: t('Theoretical Form'), render: () => <TheoreticalTab /> });
    else this.loadingTheoreticals = false;
    if (additionalTabs.practical) panes.push({ menuItem: t('Practical Form'), render: () => <PracticalTab /> });
    else this.loadingPracticals = false;

    return panes;
  };

  private isCheckListTabVisible = () => {
    const currentUser = this.identityService.getUserInfo();
    const event = this.props.eventFormStore.state.item;

    if (!event.hasCheckList) {
      return false;
    }
    const existRole = event.checkLists.map(e => {
      return (
        e.checkListConfig &&
        e.checkListConfig.rolesThatCreateCheckLists &&
        e.checkListConfig.rolesThatCreateCheckLists.includes(this.identityService.getUserInfo().activeRole)
      );
    });
    return (event.checkLists.length > 0 && existRole.any(e => e === true)) || IdentityService.isAdmin(currentUser);
  };

  private isFeedbackTabInVisible = () => {
    const eventStatus = this.props.eventFormStore.state.item.status.toString();
    return eventStatus === 'Draft' || eventStatus === 'Planned' || !this.props.eventFormStore.state.item.hasFeedbackDetails;
  };

  render() {
    const { readOnly, isOnlySupportDetailsEnabled, mode, toLoadingState } = this.props;
    return (
      <TabWithHeader
        header={<EventFormHeader recalculating={toLoadingState} mode={mode} readOnly={isOnlySupportDetailsEnabled || readOnly} />}
        footer={<EventWarnings />}
        panes={this.buildPanes()}
      />
    );
  }
}

export default withTranslation()(EventFormTabMenu);
