import { EventDto, EventStatus } from 'stores/events/events-store';
import { IdentityService, Roles } from 'services/identity-service';
import { container } from 'inversify.config';
import { EventFormViewModel } from 'stores/events/event-form-store';
import { StatusConfig, EventTypeCategory } from 'stores/configuration/events-workflow/event-types-store';
import { replaceAll, isNullOrWhiteSpaces, trim } from './useful-functions';
import { getEventOwners, isGEA, isGMA } from './event-type-utils';
import { LocationDto } from 'stores/configuration/locations/locations-store';

export const getParentRequest = ({ requests, parentRequestId, friendlyId }: EventDto) => {
  if (!requests || requests.length <= 0 || !parentRequestId) return null;
  return requests.firstOrDefault(x => x.id === parentRequestId);
};

export const getRequestedLocation = (event: EventDto) => {
  let parentRequest = getParentRequest(event);
  if (!parentRequest) return null;
  return parentRequest.requestLocation;
};

export function decamelCase(text: string) {
  return text.replace(/([a-z](?=[A-Z]))/g, '$1 ');
}

export function titlelizeCamelCase(text: string) {
  if (isNullOrWhiteSpaces(text)) return '';
  let t = trim(text);
  t = t[0].toUpperCase() + t.slice(1);
  return decamelCase(t);
}

export function isEventInstructor(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const userInfo = identityService.getUserInfo();
  return IdentityService.isInstructor(userInfo) && isCurrentUserEventInstructor(eventFormViewModel);
}

export function hasAdministrationRights(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const hasUserAdminRights = identityService.isInRole(Roles.Admin) || isUserEventOwner(eventFormViewModel);
  return hasUserAdminRights;
}

function eventStatusIsPlanedOrInProgressAndCurrentUserIsInstructor(eventFormViewModel: EventFormViewModel): boolean {
  const currentUserIsInstructor = isCurrentUserEventInstructor(eventFormViewModel);
  const eventStatus = eventFormViewModel.status.toString();
  const correctEventStatus = eventStatus === 'InProgress' || eventStatus === 'Planned';

  let rolesRequiredToValidate = null;

  switch (eventStatus) {
    case 'InProgress':
      rolesRequiredToValidate = eventFormViewModel.eventType.inProgress.rolesRequiredToValidateStatus;
      break;
    case 'Planned':
      rolesRequiredToValidate = eventFormViewModel.eventType.planned.rolesRequiredToValidateStatus;
      break;
    case 'Completed':
    case 'Draft':
    case 'Closed':
    default:
      rolesRequiredToValidate = null;
  }
  const hasValidations = (rolesRequiredToValidate || []).length > 0;
  return !hasValidations && currentUserIsInstructor && correctEventStatus;
}

export function hasAdministrationRightsOrIsEventStatusPlannedInProgressAndCurrentUserIsInstructor(
  eventFormViewModel: EventFormViewModel
): boolean {
  const identityService = container.get(IdentityService);
  const hasUserAdminRights =
    identityService.isInRole(Roles.Admin) ||
    isUserEventOwner(eventFormViewModel) ||
    eventStatusIsPlanedOrInProgressAndCurrentUserIsInstructor(eventFormViewModel);
  return hasUserAdminRights;
}

export function hasCloseEventRights(): boolean {
  const identityService = container.get(IdentityService);
  const userInfo = identityService.getUserInfo();
  return IdentityService.isAdminPocPlannerOrInstructor(userInfo);
}

export function isUserEventOwner(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const userInfo = identityService.getUserInfo();

  const owners = getEventOwners(eventFormViewModel.eventType);
  const isUserEventOwner =
    owners.includes(replaceAll(userInfo.activeRole, ' ', '')) &&
    !(userInfo.activeRole === 'Instructor' && !isCurrentUserEventInstructor(eventFormViewModel));

  return isUserEventOwner;
}

export function isCurrentUserEventOwner(event: EventDto): boolean {
  if (event == null) return false;
  const identityService = container.get(IdentityService);
  const userInfo = identityService.getUserInfo();
  if (userInfo == null) return false;
  const owners = getEventOwners(event.eventType);
  return owners.includes(replaceAll(userInfo.activeRole, ' ', ''));
}

export function isCurrentUserEventInstructor(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const isCurrentUserEventInstructor =
    identityService.getUserInfo().activeRole === 'Instructor' &&
    eventFormViewModel.hasInstructors &&
    eventFormViewModel.instructors.any(x => x.user && x.user.id === identityService.userId);

  return isCurrentUserEventInstructor;
}

export function isCurrentUserEventLeadInstructor(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  return (
    identityService.getUserInfo().activeRole === 'Instructor' &&
    eventFormViewModel.hasInstructors &&
    eventFormViewModel.instructors.any(x => x.user.id === identityService.userId && x.role.toString() === 'Lead')
  );
}

export function getNextStatus(eventFormViewModel: EventFormViewModel): EventStatus {
  const sequence = getEventStatusSequence(eventFormViewModel);
  const index = sequence.indexOf(eventFormViewModel.status);

  if (index + 1 >= sequence.length) return null;

  return sequence[index + 1];
}

export function getPreviousStatus(eventFormViewModel: EventFormViewModel): EventStatus {
  const sequence = getEventStatusSequence(eventFormViewModel);
  const index = sequence.indexOf(eventFormViewModel.status);

  if (index - 1 < 0) return null;

  return sequence[index - 1];
}

export function getEventStatusSequence(eventFormViewModel: EventFormViewModel): EventStatus[] {
  const sequence = [];

  if (eventFormViewModel.eventType.draft.active) sequence.push(EventStatus[EventStatus.Draft]);
  if (eventFormViewModel.eventType.planned.active) sequence.push(EventStatus[EventStatus.Planned]);
  if (eventFormViewModel.eventType.inProgress.active) sequence.push(EventStatus[EventStatus.InProgress]);
  if (eventFormViewModel.eventType.completed.active) sequence.push(EventStatus[EventStatus.Completed]);
  if (eventFormViewModel.eventType.closed.active) sequence.push(EventStatus[EventStatus.Closed]);

  return sequence;
}

export function getCurrentEventStatusConfiguration(eventFormViewModel: EventFormViewModel): StatusConfig {
  if (eventFormViewModel.status.toString() === 'Draft') return eventFormViewModel.eventType.draft;
  if (eventFormViewModel.status.toString() === 'Planned') return eventFormViewModel.eventType.planned;
  if (eventFormViewModel.status.toString() === 'InProgress') return eventFormViewModel.eventType.inProgress;
  if (eventFormViewModel.status.toString() === 'Completed') return eventFormViewModel.eventType.completed;
  if (eventFormViewModel.status.toString() === 'Closed') return eventFormViewModel.eventType.closed;
}

export function isUserAnEventActor(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const activeRole = identityService.activeRole.replace(' ', '');
  let rolesRequired = getCurrentEventStatusConfiguration(eventFormViewModel).rolesRequiredToValidateStatus;

  if (!rolesRequired.includes(activeRole)) return false;

  return activeRole !== 'Instructor' || isCurrentUserEventInstructor(eventFormViewModel);
}

export function isUserAnEventActorWithValidations(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const activeRole = identityService.activeRole.replace(' ', '');

  let rolesRequired = getCurrentEventStatusConfiguration(eventFormViewModel).rolesRequiredToValidateStatus;
  if (hasEventValidations(eventFormViewModel) && !rolesRequired.includes(activeRole)) return false;

  return activeRole !== 'Instructor' || isCurrentUserEventInstructor(eventFormViewModel);
}

export function hasEventValidations(eventFormViewModel: EventFormViewModel): boolean {
  const eventStatus = eventFormViewModel?.status.toString();
  let rolesRequiredToValidateStatus = null;

  switch (eventStatus) {
    case 'Planned':
      rolesRequiredToValidateStatus = eventFormViewModel?.eventType.planned.rolesRequiredToValidateStatus;
      break;
    case 'Completed':
      rolesRequiredToValidateStatus = eventFormViewModel?.eventType.completed.rolesRequiredToValidateStatus;
      break;
    case 'InProgress':
      rolesRequiredToValidateStatus = eventFormViewModel?.eventType.inProgress.rolesRequiredToValidateStatus;
      break;
    case 'Draft':
      rolesRequiredToValidateStatus = eventFormViewModel?.eventType.draft.rolesRequiredToValidateStatus;
      break;
    case 'Closed':
    default:
      rolesRequiredToValidateStatus = null;
  }

  return rolesRequiredToValidateStatus && (rolesRequiredToValidateStatus || []).length > 0;
}

export function hasInstructorRights(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  return (
    identityService.isInRole(Roles.Instructor) &&
    (eventFormViewModel.status.toString() === 'InProgress' || eventFormViewModel.status.toString() === 'Completed') &&
    isCurrentUserEventInstructor(eventFormViewModel)
  );
}

export function isPocOrFactoryLeadInExtendedWf(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  return (
    (identityService.isInRole(Roles.PoC) || identityService.isInRole(Roles.FactoryLead)) &&
    eventFormViewModel.status.toString() !== 'Draft' &&
    (isGEA(eventFormViewModel.eventType) || isGMA(eventFormViewModel.eventType))
  );
}

export function isPoCAndEventStatusInPlannedOrInProgress(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  return (
    !isPocOrFactoryLeadInExtendedWf(eventFormViewModel) &&
    identityService.isInRole(Roles.PoC) &&
    (eventFormViewModel.status.toString() === 'Planned' || eventFormViewModel.status.toString() === 'InProgress')
  );
}

export function hasUserAlreadyPerformedValidation(eventFormViewModel: EventFormViewModel): boolean {
  const identityService = container.get(IdentityService);
  const statusConfiguration = getCurrentEventStatusConfiguration(eventFormViewModel);

  if (
    identityService.hasRole(Roles.Employee) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'Employee') &&
    eventFormViewModel.statusValidation.validatedByEmployees.any(x => x === identityService.userId)
  )
    return true;

  if (
    identityService.hasRole(Roles.Instructor) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'Instructor') &&
    eventFormViewModel.statusValidation.validatedByInstructors.any(x => x === identityService.userId)
  )
    return true;

  if (
    identityService.hasRole(Roles.PoC) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'PoC') &&
    eventFormViewModel.statusValidation.validatedByPoC.any(x => x === identityService.userId)
  )
    return true;

  if (
    identityService.hasRole(Roles.Planner) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'Planner') &&
    eventFormViewModel.statusValidation.validatedByPlanners.any(x => x === identityService.userId)
  )
    return true;

  if (
    identityService.hasRole(Roles.PlannerMTC) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'PlannerMTC') &&
    eventFormViewModel.statusValidation.validatedByPlannersMTC.any(x => x === identityService.userId)
  )
    return true;

  if (
    identityService.hasRole(Roles.FactoryLead) &&
    statusConfiguration.rolesRequiredToValidateStatus.any(x => x === 'FactoryLead') &&
    eventFormViewModel.statusValidation.validatedByFactoryLead.any(x => x === identityService.userId)
  )
    return true;

  return false;
}

export function isOfCategory(eventFormViewModel: EventFormViewModel, category: EventTypeCategory): boolean {
  if (eventFormViewModel == null || eventFormViewModel.eventType == null || eventFormViewModel.eventType.eventTypeCategory == null)
    return null;
  return EventTypeCategory[eventFormViewModel.eventType.eventTypeCategory] === category;
}

export const getRequestsLocation = (event: EventDto) =>
  (event?.requests || []).length <= 0
    ? null
    : event.requests
        .map(x => x?.requestLocation)
        .distinct(x => x?.id)
        .toArray<LocationDto>();
