import React, { Component } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import {
  hasAdministrationRights,
  hasInstructorRights,
  isPoCAndEventStatusInPlannedOrInProgress,
  isPocOrFactoryLeadInExtendedWf
} from 'utils/event-utils';
import { EventFormStore, EventDocumentViewState, buildInstructorsViewModel } from 'stores/events/event-form-store';
import { connect } from 'redux-scaffolding-ts';
import HaveWarningsPopup from '../popups/have-warnings-popup/have-warnings-popup';
import { ItemResult } from 'stores/types';
import { EventDto } from 'stores/events/events-store';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';

export interface EditButtonForPocsProps extends WithTranslation {
  readOnly: boolean;
  eventFormStore?: EventFormStore;
  onSave?: (save: 'save') => void;
  onEdit?: (edit: 'edit') => void;
}

export interface EditButtonForPocsState {
  showHaveWarningsModal: boolean;
}

@connect(['eventFormStore', EventFormStore])
class EditButtonForPocs extends Component<EditButtonForPocsProps, EditButtonForPocsState> {
  state: EditButtonForPocsState = {
    showHaveWarningsModal: false
  };

  handleOnAcceptedWarnings = () => {
    this.saveEvent();
    this.setState({ showHaveWarningsModal: false });
  };

  private handleOnSaveClick = () => {
    const event = this.props.eventFormStore.state.item;
    if (event.warningMessages.length > 0 && (event.status.toString() === 'Draft' || event.status.toString() === 'Planned')) {
      this.setState({ showHaveWarningsModal: true });
      return;
    }

    this.saveEvent();
  };

  private saveEvent = async () => {
    const x = await this.props.eventFormStore.updateEvent();
    if (x.isSuccess) {
      const docs = ((x as ItemResult<EventDto>).item.supportingDocuments || []).map(d => ({
        ...d,
        path: null,
        state: EventDocumentViewState.Unmodified
      }));
      const instructorsVM = buildInstructorsViewModel((x as ItemResult<EventDto>).item.instructors || []);
      this.props.eventFormStore.change({ supportingDocuments: docs, instructors: instructorsVM });
      ToastComponent({ text: this.props.t('Form successfully saved!'), type: 'success-toast' });
    }
    if (x?.isSuccess) {
      this.props.onSave && this.props.onSave('save');
      this.props.eventFormStore.change({ shouldRecalculate: true });
    } else this.props.eventFormStore.state.result.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'warning-toast' }));
  };

  private handleOnEditClick = () => {
    this.props.onEdit && this.props.onEdit('edit');
  };

  private isEditButtonForPocsVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    const isVisibleEditButton = this.isEditButtonVisible();
    const isVisible = !isVisibleEditButton && isPoCAndEventStatusInPlannedOrInProgress(event);
    return isVisible;
  };

  private hideWarningsModal = () => this.setState({ showHaveWarningsModal: false });

  private isEditButtonVisible = (): boolean => {
    const event = this.props.eventFormStore.state.item;
    return hasAdministrationRights(event) || hasInstructorRights(event) || isPocOrFactoryLeadInExtendedWf(event);
  };

  render() {
    const { t, readOnly } = this.props;
    const isVisible = this.isEditButtonForPocsVisible();

    if (!isVisible) return null;

    return (
      <>
        {!readOnly && (
          <Button
            className="form__actions__button form__white-btn"
            positive
            icon="save"
            labelPosition="right"
            content={t('Save')}
            onClick={this.handleOnSaveClick}
          />
        )}

        {readOnly && (
          <Button
            className="form__actions__button form__white-btn"
            icon="edit"
            labelPosition="right"
            content={t('Edit')}
            onClick={this.handleOnEditClick}
          />
        )}

        <HaveWarningsPopup
          open={this.state.showHaveWarningsModal}
          onAcceptClicked={this.handleOnAcceptedWarnings}
          onCancelClicked={this.hideWarningsModal}
        />
      </>
    );
  }
}

export default withTranslation()(EditButtonForPocs);
