import React, { PureComponent, RefObject } from 'react';
import { WidgetHelper } from '@planit/bryntum-scheduler';
import './scheduler-location-style.less';
import BryntumScheduler from 'widgets/scheduler/BryntumScheduler';
import { connect } from 'redux-scaffolding-ts';
import { InvisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/invisible-filters';
import { EventsStore, EventDto, ChangeEventsStore } from 'stores/events/events-store';
import { LocationsStore, LocationDto } from 'stores/configuration/locations/locations-store';
import schedulerConfig from './scheduler-locations-config';
import { AlwaysVisibleFiltersValue } from '../shared-scheduler-components/scheduler-header/filters/always-visible-filters';
import { SchedulerEventFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-event-filter-service';
import { resolve } from 'inversify.config';
import SchedulerRequestList from '../shared-scheduler-components/request-component/scheduler-request-list';
import SchedulerHeaderComponent, { FilterValues } from '../shared-scheduler-components/scheduler-header/scheduler-header.component';
import { RequestDto, ChangeRequestStore, RequestsStore } from '../../../../stores/requests/requests-store';
import { SchedulerPeriodService, DatePeriod } from '../shared-scheduler-components/events/scheduler-period-service';
import { isCurrentUserEventOwner } from 'utils/event-utils';
import EventForm from '../../events/event-form/event-form';
import Drag from '../util/Drag';
import { OrderDefinition, Query, QueryResult } from 'stores/dataStore';
import { nameof } from 'utils/object';
import { RequestPopupFilterValues } from '../shared-scheduler-components/request-component/request-popup-filters';
import { PublicHolidayConfigurationStore, PublicHolidayDto } from 'stores/public-holidays/public-holidays-store';
import { WorkingDaysStore, WorkingDayDto, CreateWorkingDaysDto } from 'stores/working-days/working-days-store';
import { IdentityService, Roles } from 'services/identity-service';
import {
  //getResourceTR,
  //getAllLocationWorkingDays,
  getAllLocationPublicHolidays,
  ResourceTR,
  manageEventDates,
  buildRequestFilter,
  getLocationDefaultRequestFilterValue
} from '../shared-scheduler-components/scheduler-methods';
import { ToastComponent } from '../util/toast-component';
import { DateTimeService } from 'services/datetime-service';
import { buildEventViewModel } from 'stores/events/event-form-store';
import { isExtendedWorkflow } from 'utils/event-type-utils';
import { EventFormActions } from 'site/pages/events/event-form/buttons/action-buttons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { PublicHolidayConf } from '../shared-scheduler-components/scheduler-poc-config/config-tabs/public-holidays-tab';
import { Dimmer, Loader } from 'semantic-ui-react';
import { SchedulerResourceFilterService } from '../shared-scheduler-components/scheduler-header/filters/scheduler-resource-filter-service';
import { RequestFormActions } from 'site/pages/requests/request-form/request-form';
import { SelectionItem } from 'widgets/form/selectionInput';
import { EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { CountryPublicHolidayConf } from '../shared-scheduler-components/scheduler-poc-config/config-tabs/country-public-holidays-tab';

export interface PlannerLocationPageProps extends WithTranslation {
  locations?: LocationsStore;
  events?: EventsStore;
  publicHolidayConfiguration?: PublicHolidayConfigurationStore;
  workingDays?: WorkingDaysStore;
  changeRequestsStore?: ChangeRequestStore;
  changeEventsStore?: ChangeEventsStore;
  requestsStore?: RequestsStore;
}
export interface PlannerLocationPageState {
  barMargin: number;
  selectedEvent: EventDto;
  eventsVersion: number;
  resourcesVersion: number;
  events: any;
  resources: any;
  filterValues: FilterValues;
  showRequestColumn: boolean;
  resourceTimeRanges: any;
  requestsRef: RefObject<any>;
  filtersDisabled: boolean;
  showAdditionalFilters: boolean;
  requests: RequestDto[];
  currentRequestsQuery: Query;
  hasMoreRequest: boolean;
  eventPreview: EventDto;
  mainRequest: RequestDto;
  mergeRequestIds: string[];
  maskRequestTable: any;
  locations?: any;
  eventEdition: boolean;
  loading: boolean;
  isGlobalReporting: boolean;
}

@connect(
  ['locations', LocationsStore],
  ['events', EventsStore],
  ['publicHolidayConfiguration', PublicHolidayConfigurationStore],
  ['workingDays', WorkingDaysStore],
  ['changeRequestsStore', ChangeRequestStore],
  ['changeEventsStore', ChangeEventsStore],
  ['requestsStore', RequestsStore]
)
class PlannerLocationPage extends PureComponent<PlannerLocationPageProps, PlannerLocationPageState> {
  pageSize = 15;
  @resolve(SchedulerResourceFilterService)
  private schedulerResourceFilterService: SchedulerResourceFilterService;

  @resolve(IdentityService)
  private identityService: IdentityService;

  @resolve(SchedulerEventFilterService)
  private schedulerEventFilterService: SchedulerEventFilterService;

  @resolve(SchedulerPeriodService)
  private schedulerPeriodService: SchedulerPeriodService;
  _mounted = false;
  activeRole: string;

  dragHelper = new Drag({});
  constructor(props) {
    super(props);
    this.activeRole = this.identityService.activeRole;
    this.state = {
      barMargin: 5,
      loading: false,
      selectedEvent: null,
      eventsVersion: 0,
      resourcesVersion: 0,
      events: [],
      requests: [],
      maskRequestTable: null,
      resources: [],
      showAdditionalFilters: true,
      showRequestColumn: true,
      filterValues: this.schedulerEventFilterService.getDefaultFilterValues(),
      resourceTimeRanges: null,
      filtersDisabled: true,
      requestsRef: null,
      currentRequestsQuery: null,
      hasMoreRequest: true,
      eventPreview: null,
      mainRequest: null,
      mergeRequestIds: [],
      locations: [],
      eventEdition: null,
      isGlobalReporting: false
    };
  }

  refs: { scheduler: BryntumScheduler };

  requestsListRef = React.createRef<HTMLDivElement>();

  public get schedulerDatePeriod() {
    return this.schedulerPeriodService.toSchedulerPeriod(this.state.filterValues.alwaysVisibleFilterValues.period);
  }

  handleSelectionChange = selected => this.setState({ selectedEvent: (selected.length && selected[0].name) || '' });

  IsGlobalReporting = () => {
    const allQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }];
    const locationsQuery: any = { ...allQuery, orderBy };

    const locationsByRoles = this.identityService.getUserInfo()?.locationsByRoles;
    const locationsByActiveRole = locationsByRoles[this.activeRole] as string[];
    locationsQuery.filter = [{ Id: { in: { type: 'guid', value: locationsByActiveRole } } }];

    const locations = this.props.locations
      .getAllAsync(locationsQuery)
      .then(response => {
        const globalReporting =
          this.activeRole === 'Reporting' && (response?.items || []).any(x => x.location === 'Global' && x.code === 'GLB');

        this._mounted && this.setState({ isGlobalReporting: globalReporting });

        return globalReporting;
      })
      .catch(_ => {
        this.setState({ isGlobalReporting: false });
      });

    return locations;
  };

  initScheduler = async () => {
    const { isGlobalReporting } = this.state;
    this._mounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler.schedulerEngine.element, 'FETCHING DATA');
    const allQuery = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };
    const orderBy: OrderDefinition[] = [{ direction: 'Ascending', field: nameof<LocationDto>('location'), useProfile: false }];
    const locationsQuery: any = { ...allQuery, orderBy };

    if (this.activeRole === 'PoC' || this.activeRole === 'Factory Lead' || (this.activeRole === 'Reporting' && !isGlobalReporting)) {
      const locationsByRoles = this.identityService.getUserInfo()?.locationsByRoles;
      const locationsByActiveRole = locationsByRoles[this.activeRole] as string[];
      locationsQuery.filter = [{ Id: { in: { type: 'guid', value: locationsByActiveRole } } }];
    }

    const newEventsPromise = this.getEvents(this.schedulerDatePeriod);
    const locationsPromise = this.props.locations.getAllAsync(locationsQuery);
    const publicHolidaysPromise = this.props.publicHolidayConfiguration.getAllAsync(allQuery);
    const workingDaysPromise = this.props.workingDays.getAllAsync(allQuery);

    let promises = [newEventsPromise, locationsPromise, publicHolidaysPromise, workingDaysPromise];

    let newEvents: QueryResult<EventDto> = { count: 0, items: [] };
    let locations: QueryResult<LocationDto> = { count: 0, items: [] };
    let publicHolidays: QueryResult<PublicHolidayDto> = { count: 0, items: [] };
    let workingDays: QueryResult<WorkingDayDto> = { count: 0, items: [] };

    let promiseResp = await Promise.allSettled(promises);

    if (promiseResp.every(x => x.status === 'fulfilled' && x.value)) {
      let results = promiseResp.map(x => (x as PromiseFulfilledResult<QueryResult<any>>).value);
      newEvents = results[0];
      locations = results[1];
      publicHolidays = results[2];
      workingDays = results[3];
    }

    const events = this.mapToBryntumEvents(newEvents.items);
    const resourceTimeRanges = this.mapToResourceTimeRanges(locations.items, publicHolidays.items, workingDays.items);
    const resources = locations.items.filter(({ active }) => active);

    this._mounted &&
      this.setState(
        { locations, loading: false, eventsVersion: 1, resourcesVersion: 1, events, resources, resourceTimeRanges, filtersDisabled: false },
        () => {
          this.refs.scheduler && this.refs.scheduler.setResourceTimeRanges(this.state.resourceTimeRanges);
          maskScheduler.close();
        }
      );
  };

  async componentDidMount() {
    this._mounted = true;
    this.refs.scheduler.rowHeightHandler(64);
    await this.IsGlobalReporting();
    this.initScheduler();
    this.initRequest();
  }

  initRequest = async () => {
    const { isGlobalReporting } = this.state;
    const orderBy: OrderDefinition[] = [{ direction: 'Descending', field: nameof<RequestDto>('createdOn'), useProfile: false }];
    const filter = getLocationDefaultRequestFilterValue(this.activeRole, this.identityService.getUserInfo(), isGlobalReporting);
    const query: Query = { searchQuery: '', orderBy, filter, skip: 0, take: this.pageSize };

    this.loadRequests(query, true);
  };

  loadRequests = (query: Query, replaceCurrentRequest: boolean) => {
    this._mounted && this.setState({ maskRequestTable: WidgetHelper.mask(this.requestsListRef.current, 'FETCHING DATA') });

    return this.props.requestsStore.getAllRequestListAsync(query).then(response => {
      let requests = [...this.state.requests];
      let totalRequests = replaceCurrentRequest ? response.items : requests.concat(response.items);

      this._mounted &&
        this.setState({ currentRequestsQuery: query, requests: totalRequests, hasMoreRequest: totalRequests.length < response.count });

      this.refs.scheduler &&
        (this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)) &&
        this.dragHelper.setConfig({
          dropTargetSelector: '.sch-locations__container',
          targetSelector: '.request-table-list-item',
          onRequestEventMerge: this.onRequestEventMerge,
          onRequestsMerge: this.onRequestsMerge,
          onNewEventFromRequestDragOnLocation: this.onNewEventFromRequestDragOnLocation,
          constrain: false,
          data: totalRequests,
          schedule: this.refs.scheduler.schedulerEngine
        });

      !this.state.maskRequestTable.isDestroyed && this.state.maskRequestTable.close();

      return totalRequests;
    });
  };

  onRequestEventMerge = async (request: RequestDto, event: EventDto) => {
    try {
      this.setState({ loading: true });
      const data: any = await this.props.changeEventsStore.mergeRequestToEvent(request.id, event.id);
      this.reloadRequests();
      if (data.isSuccess) {
        ToastComponent({ text: 'Operation completed', type: 'success-toast' });
        this.reloadEvents(event.id);
      } else {
        data.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'error-toast' }));
        this.reloadEvents();
      }
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    } finally {
      this.setState({ loading: false });
    }
  };

  onRequestsMerge = async (sourceRequest: RequestDto, targetRequest: RequestDto) => {
    const { changeRequestsStore, t } = this.props;
    if ((targetRequest?.status || '').toString() === 'InProgress') {
      ToastComponent({ text: t('Cannot merge with In Progress request'), type: 'error-toast' });
      return;
    }
    const mergeRequestsIds = [sourceRequest.id, targetRequest.id];

    this.setState({ loading: true });
    try {
      const response: any = await changeRequestsStore.mergeRequests(sourceRequest.id, mergeRequestsIds);
      if (!response.isSuccess) {
        response.messages.forEach(msg => ToastComponent({ text: msg.body, type: 'error-toast' }));
        return;
      }
      ToastComponent({ text: 'Valid operation', type: 'success-toast' });
      this._mounted &&
        this.setState({
          mainRequest: sourceRequest,
          eventPreview: { ...response.item, requests: [sourceRequest, targetRequest], parentRequestId: sourceRequest.id },
          mergeRequestIds: mergeRequestsIds
        });
    } catch (error) {
      console.error({ error });
      (error?.response?.data?.messages || []).forEach(({ body }) => ToastComponent({ text: body, type: 'error-toast' }));
    } finally {
      this.setState({ loading: false });
    }
  };

  getEvents = (eventPeriods: DatePeriod, additionalFilters = []) => {
    const filter = [
      { eventDetails: { ne: null } },
      { eventDetails: { locationId: { ne: null } } },
      { eventTypeItem: { eventTypeCategory: { ne: 'Vacations' } } },
      { startDate: { le: new Date(eventPeriods.to) } },
      { endDate: { ge: new Date(eventPeriods.from) } }
    ];

    const queryEvents = {
      searchQuery: '',
      filter: [...filter, ...additionalFilters],
      orderBy: [],
      skip: 0,
      take: 100000,
      select:
        'Id, title, EventTypeId, EventTypeItem/Name, EventTypeItem/OriginalEventTypeId, EventTypeItem/EventsColor, EventTypeItem/EventsIcon, EventTypeItem/eventTypeCategory, EventStatus, plannedDuration, startDate, endDate, eventUpdatedFlag, eventWarnings, FriendlyEventId, EventInstructors, EventTrainingDetails/NumStudentsAssigned, EventTrainingDetails/DeliveryMethodId, EventDetails/ProfessionId, EventDetails/PriorityId, EventDetails/LocationId,Requests/RequestingLocationId, SupportDetails/SupportPositions'
    };

    return this.props.events.getAllAsync(queryEvents);
  };

  setEvents = events => {
    this._mounted &&
      this.setState(
        ({ eventsVersion }) => ({ eventsVersion: eventsVersion + 1, events }),
        () => this.filterEvents(this.state.filterValues.alwaysVisibleFilterValues, this.state.filterValues.invisibleFilterValues)
      );
  };

  mapToResourceTimeRanges = (locations: LocationDto[], publicHolidays: PublicHolidayDto[], workingDaysArr: WorkingDayDto[]) => {
    let resourceTimeRanges: ResourceTR[] = [];
    (locations || []).forEach(({ id }) => {
      // !Important: This weekend functionality has been disabled because it was the reason why the calendar was loading too slowly and even crashed.
      //const locationWorkingDays = workingDaysArr.find(({ locationId }) => locationId === id);
      //if (locationWorkingDays) resourceTimeRanges = [...resourceTimeRanges, ...getAllLocationWorkingDays(locationWorkingDays)];
      //else resourceTimeRanges.push(getResourceTR('weekend', null, null, id, 2, true));

      const locationPublicHolidays = publicHolidays.find(x => x.locationId === id);
      if (locationPublicHolidays) resourceTimeRanges = [...resourceTimeRanges, ...getAllLocationPublicHolidays(locationPublicHolidays)];
    });

    return resourceTimeRanges;
  };

  mapToBryntumEvents = (events: EventDto[]) => {
    const bryntumEvents = [];

    (events || []).forEach(event => {
      const { status: evStatus, startDate: from, endDate: to, id, eventDetails /*, eventType, supportDetails*/, requests } = event;
      const status = evStatus.toString();
      let draggable = false;
      if (isCurrentUserEventOwner(event) && (status === 'Draft' || status === 'Planned' || status === 'InProgress')) draggable = true;

      const startDate: any = DateTimeService.toSchedulerFromString(from);
      const endDate: any = DateTimeService.toSchedulerToString(to);
      const originalId = `${id}-${eventDetails.locationId}`;

      bryntumEvents.push({ event, startDate, endDate, id: originalId, resourceId: eventDetails.locationId, draggable });

      /*Product Backlog Item 8727: INC4542790 Incorrect Request Locations on Regional VP Role Calendar: 
      \/\/\/  Lets change it so that user sees one event only once on event location’s calendar. \/\/\/*/
      // if (isExtendedWorkflow(eventType.eventTypeCategory))
      //   (supportDetails?.supportPositions || []).forEach(({ eventPositions }, idx) =>
      //     (eventPositions || []).forEach(({ userLocationId, user: { roles, location } }, i) => {
      //       const newId = `${id}-${userLocationId}-${idx}-${i}`;
      //       const requestLocation = (roles || []).find(x => x.role.name === 'Employee')?.location || location;
      //       const existingEvent = bryntumEvents.find(
      //         ({ event: { id: eId }, resourceId }) => eId === id && (resourceId === userLocationId || resourceId === requestLocation?.id)
      //       );
      //       if (!existingEvent) {
      //         // console.log(bryntumEvents);
      //         bryntumEvents.push({
      //           event: { ...event, requestLocation, requestedLocation: true },
      //           startDate,
      //           endDate,
      //           id: newId,
      //           resourceId: requestLocation?.id || userLocationId,
      //           draggable: false
      //         });
      //       }
      //     })
      //   );
      /* /\/\/\/\                                                                           /\/\/\/\
      Product Backlog Item 8727: INC4542790 Incorrect Request Locations on Regional VP Role Calendar: 
      Lets change it so that user sees one event only once on event location’s calendar. 
      */

      if ((requests || []).length <= 0) return;
      (requests || []).forEach(({ requestLocation }) => {
        if (requestLocation.id === eventDetails.locationId) return;

        bryntumEvents.push({
          event: { ...event, requestLocation, requestedLocation: true },
          startDate,
          endDate,
          id: `${event.id}-${requestLocation.id}`,
          resourceId: requestLocation.id,
          draggable: false
        });
      });
    });

    return bryntumEvents;
  };

  handleRowHeight = value => {
    this.refs.scheduler?.rowHeightHandler(value);
  };

  handleViewPresetChange = presetValue => {
    this.setState(({ eventsVersion }) => ({ events: [], eventsVersion: eventsVersion + 1 }));
    if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
      this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
    this.refs.scheduler && this.refs.scheduler.viewPresetChangeHandler(presetValue);
    this.refs.scheduler?.setResourceTimeRanges(this.state.resourceTimeRanges);
    this.reloadEvents();
  };

  onScrollEndHandler = ({ x, maxX }) => {
    const period = { ...this.state.filterValues.alwaysVisibleFilterValues.period };

    const hasMak = this.refs.scheduler?.schedulerEngine?.element?.hasOwnProperty('mask') || false;
    if (hasMak) {
      const entries = Object.entries(this.refs.scheduler?.schedulerEngine?.element);
      const plain = Object.fromEntries(entries.map(([k, v]) => [k, v]));
      const maskVal: any = plain['mask'];
      if (maskVal && maskVal._text) {
        return;
      }
    }
    const distance = 50;
    if (maxX - distance < x) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .add(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .add(1, 'months')
        .toISOString();

      this._mounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            events: [],
            eventsVersion: eventsVersion + 1,
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    } else if (x < distance) {
      if (this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll)
        this.refs.scheduler.schedulerEngine.resourceTimeRangeStore.removeAll();
      period.to = DateTimeService.toMoment(period.to)
        .subtract(1, 'months')
        .toISOString();
      period.from = DateTimeService.toMoment(period.from)
        .subtract(1, 'months')
        .toISOString();

      this._mounted &&
        this.setState(
          ({ filterValues: { alwaysVisibleFilterValues, ...rest }, eventsVersion }) => ({
            events: [],
            eventsVersion: eventsVersion + 1,
            filterValues: { ...rest, alwaysVisibleFilterValues: { ...alwaysVisibleFilterValues, period } }
          }),
          this.reloadEvents
        );
    }
  };

  onToggleRequestHandler = () => {
    if (this.state.showRequestColumn && !this.state.maskRequestTable.isDestroyed) this.state.maskRequestTable.close();

    this._mounted && this.setState(({ showRequestColumn }) => ({ showRequestColumn: !showRequestColumn }));
  };

  private handleOnFilterValueChange = (filterValues: FilterValues) => {
    const schedulerPeriod = this.schedulerPeriodService.toSchedulerPeriod(filterValues.alwaysVisibleFilterValues.period);
    const previousDatePeriod = { ...this.schedulerDatePeriod };
    const samePeriod = this.schedulerPeriodService?.areEquals(previousDatePeriod, schedulerPeriod);
    if (!samePeriod) {
      this.refs.scheduler?.schedulerEngine?.resourceTimeRangeStore?.removeAll?.();
      this._mounted &&
        this.setState(({ eventsVersion }) => ({ events: [], eventsVersion: eventsVersion + 1, filterValues }), this.reloadEvents);
    } else this.setState({ filterValues });
    if (!samePeriod) return;
    this.filterEvents(filterValues.alwaysVisibleFilterValues, filterValues.invisibleFilterValues);
  };

  private filterEvents = (alwaysVisibleFilterValues: AlwaysVisibleFiltersValue, invisibleFilterValues: InvisibleFiltersValue) => {
    this.refs.scheduler?.eventStore?.filter?.({
      filters: eventRecord =>
        this.schedulerEventFilterService.fullfillsAllFilters(eventRecord, alwaysVisibleFilterValues, invisibleFilterValues),
      replace: true
    });
    this.refs.scheduler?.resourceStore?.filter?.({
      filters: resource =>
        this.schedulerResourceFilterService.fullfillsAllFilters(resource, alwaysVisibleFilterValues, invisibleFilterValues, true),
      replace: true
    });
  };

  componentWillUnmount = () => {
    this._mounted = false;
    this.dragHelper.destroy();
  };

  getEventsPerLocation = (period: DatePeriod, additionalFilters) => {
    return this.getEvents(period, additionalFilters).then(events => this.mapToBryntumEvents(events.items));
  };

  reloadEvents = async (newEventId?: string) => {
    this._mounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine?.element, 'FETCHING DATA');
    const events = await this.getEventsPerLocation(this.schedulerDatePeriod, []);
    const changes: any = { loading: false };
    if (newEventId) {
      let selectedEvent = (events || []).find(({ id }) => id === newEventId);
      if (!selectedEvent) selectedEvent = await this.props.events.getById(newEventId);
      changes.selectedEvent = selectedEvent;
    }
    this.setEvents(events);
    maskScheduler.close();
    this._mounted && this.setState(changes);
  };

  handleOnCreatedEvent = (isSucess: boolean) => {
    if (isSucess) this.reloadEvents();
  };

  handleOnCreatedRequest = (isSucess: boolean) => {
    if (isSucess) this.reloadRequests();
  };

  handleOnCloseFormView = (actionPerformed?: EventFormActions, payload?: any) => {
    if (this._mounted) {
      this.setState({ selectedEvent: null, eventEdition: null });
      if (!(actionPerformed && actionPerformed === 'close')) {
        this.reloadEvents();
        this.reloadRequests();
      }
    }
  };

  handleOnRequestValueChange = (value: RequestPopupFilterValues) => {
    const filter = buildRequestFilter(value, this.activeRole, this.identityService.getUserInfo());
    const query = { ...this.state.currentRequestsQuery, skip: 0, take: this.pageSize, filter };

    this.loadRequests(query, true);
  };

  handleOnRequestListScrollEnd = () => {
    if (this.props.requestsStore.state.isBusy || !this.state.hasMoreRequest) return;

    const query = { ...this.state.currentRequestsQuery };
    query.skip = this.pageSize + query.skip;
    query.take = this.pageSize;

    this.loadRequests(query, false);
  };

  handleOnRequestChanged = (request?: RequestDto, actionPerformed?: RequestFormActions) => {
    if (!(actionPerformed && actionPerformed === 'close')) {
      this.reloadRequests();

      if (request && request.eventCreatedId && !isExtendedWorkflow(request.eventType.eventTypeCategory))
        this.reloadEvents(request.eventCreatedId);
      else this.reloadEvents();
    }
  };

  reloadRequests = () => {
    const query = { ...this.state.currentRequestsQuery };
    query.take = query.skip;
    query.skip = 0;

    this.loadRequests(query, true);
  };

  handleOnCloseEventPreview = (actionName: EventFormActions = undefined, selectedEvent: any = null) => {
    this._mounted &&
      this.setState({ mainRequest: null, eventPreview: null }, () => {
        if (!(actionName && actionName === 'close')) {
          this.reloadEvents();
          this.reloadRequests();
        }
        if (this._mounted) return { selectedEvent };
      });
  };

  onNewEventFromRequestDragOnLocation = (request: RequestDto, location: LocationDto, date: string) => {
    this.setState({ loading: true });
    this.props.changeRequestsStore.mergeRequests(request.id, [request.id]).then(
      ({ item: eventPreview }) => {
        this.setState({ loading: false });
        ToastComponent({ text: 'Valid operation', type: 'success-toast' });
        if (eventPreview) {
          if (eventPreview.eventDetails) {
            eventPreview.eventDetails.location = location;
            eventPreview.eventDetails.locationId = location.id;
          }
          eventPreview.startDate = date;
          eventPreview.endDate = DateTimeService.toMoment(date)
            .add(eventPreview.plannedDuration > 0 ? +eventPreview.plannedDuration - 1 : 0, 'days')
            .toISOString();

          eventPreview.requests = [request];
          eventPreview.parentRequestId = request.id;
        }

        this._mounted && this.setState({ mainRequest: request, eventPreview });
      },
      error => {
        console.log(error);
        this.setState({ loading: false });
        ToastComponent({ text: 'Invalid operation', type: 'error-toast' });
      }
    );
  };

  dropHandler = async ({ context: { record, newResource, origStart, startDate: start, endDate, finalize } }) => {
    const { event }: { event: EventDto } = record;
    const from: any = DateTimeService.toMoment(start, true).startOf('d');
    const to: any = DateTimeService.toMoment(endDate, true).startOf('d');
    finalize(false);
    if (from.diff(origStart, 'd') === 0 && record.resourceId === newResource.id) {
      record.setStartDate && record.setStartDate(from.toDate());
      record.setEndDate && record.setEndDate(to.endOf('d').toDate());
      return;
    }
    const dateDifference = from.diff(DateTimeService.toMoment(origStart).startOf('d'), 'd');
    const operator = dateDifference >= 0 ? 'add' : 'subtract';

    this.setState({ loading: true });
    let eventComplete = await this.props.events.getById(event.id);
    this.setState({ loading: false });
    let copy = { ...eventComplete };

    const newEvent = manageEventDates(copy, dateDifference, operator);
    const eventDetails = { ...copy.eventDetails, location: { ...newResource.data }, locationId: newResource.data.id };

    this._mounted && this.setState({ selectedEvent: { ...newEvent, eventDetails }, eventEdition: false });
  };

  reloadWorkingDaysPublicHolidaysHandler = async (data: CountryPublicHolidayConf | PublicHolidayConf | CreateWorkingDaysDto = null) => {
    if (!data) return;
    this._mounted && this.setState({ loading: true });
    const maskScheduler: any = WidgetHelper.mask(this.refs.scheduler?.schedulerEngine?.element, 'FETCHING DATA');
    const allQuery: any = { searchQuery: '', orderBy: [], skip: 0, take: 1000 };
    const publicHolidays = await this.props.publicHolidayConfiguration.getAllAsync(allQuery);
    const workingDays = await this.props.workingDays.getAllAsync(allQuery);
    const resourceTimeRanges = this.mapToResourceTimeRanges(this.state.locations?.items, publicHolidays?.items, workingDays?.items);
    this.setState({ resourceTimeRanges }, () => {
      setTimeout(() => {
        this.refs.scheduler.setResourceTimeRanges(resourceTimeRanges);
        maskScheduler.close();
        this._mounted && this.setState({ loading: false });
      }, 1000);
    });
  };

  dblClickHandler = async ({ eventRecord: { event: selectedEvent } }) => {
    if (this._mounted) {
      this.setState({ loading: true });
      let elem = await this.props.events.getById(selectedEvent.id);
      this.setState({ loading: false });
      this.setState({ selectedEvent: elem });
    }
  };

  beforeEventDrag = ({ eventRecord, ...rest }) => {
    const newEndDate = DateTimeService.toMoment(eventRecord.endDate, true).startOf('d');
    eventRecord.setEndDate && eventRecord.setEndDate(newEndDate.toDate());
    return { eventRecord, ...rest };
  };

  updateLoadingState = (isLoading: boolean) => {
    this._mounted && this.setState({ loading: isLoading });
  };

  private RequestDtosToSelectionItems(requests: RequestDto[]) {
    let options: SelectionItem[] = [];
    if (requests.length > 0) {
      let types = requests
        .map(x => x.eventType)
        .distinct<string>(x => x.id)
        .toArray<EventTypeDto>();
      options = types.map(x => ({ value: x.id, text: x.name } as SelectionItem));
    }
    return options;
  }

  render() {
    const { t } = this.props;
    const { resourcesVersion, events, resources, requests, eventEdition, selectedEvent, eventPreview, loading, ...rest } = this.state;
    const { showAdditionalFilters, showRequestColumn, filterValues, filtersDisabled, barMargin, eventsVersion, resourceTimeRanges } = rest;

    const containerClassName = `sch-locations__container${showAdditionalFilters ? '' : ' grow'}`;
    let schedulerClassName = 'b-react-scheduler-container';

    if (showRequestColumn) document.querySelector('.b-react-scheduler-container')?.classList.remove('collapse');
    else document.querySelector('.b-react-scheduler-container')?.classList.add('collapse');

    const ls = { eventDblClick: this.dblClickHandler, beforeEventDropFinalize: this.dropHandler, beforeEventDrag: this.beforeEventDrag };

    return (
      <div className={containerClassName}>
        <Dimmer active={loading} style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
          <Loader indeterminate>{t('Loading...')}</Loader>
        </Dimmer>
        <SchedulerHeaderComponent
          filterValues={filterValues}
          filtersDisabled={filtersDisabled}
          onFilterValueChange={this.handleOnFilterValueChange}
          onRowHeightChange={this.handleRowHeight}
          onViewPresetChange={this.handleViewPresetChange}
          onCreatedEvent={this.handleOnCreatedEvent}
          onCreatedRequest={this.handleOnCreatedRequest}
          onReloadWorkingDaysPublicHolidays={this.reloadWorkingDaysPublicHolidaysHandler}
        />
        <div className={`sch-locations__scheduler-component${showAdditionalFilters ? '' : ' grow'}`}>
          <BryntumScheduler
            {...schedulerConfig}
            ref={'scheduler'}
            rtr={resourceTimeRanges}
            className={schedulerClassName}
            barMargin={barMargin}
            eventRenderer={data => schedulerConfig.eventRenderer(data, this.refs.scheduler?.schedulerEngine)}
            eventsVersion={eventsVersion}
            resourcesVersion={resourcesVersion}
            events={events}
            resources={resources}
            startDate={this.schedulerDatePeriod.from}
            endDate={this.schedulerDatePeriod.to}
            eventDragFeature={this.identityService.isInRole(Roles.Planner) || this.identityService.isInRole(Roles.PlannerMTC)}
            listeners={ls}
            onScrollEnd={this.onScrollEndHandler}
            onEventSelectionChange={this.handleSelectionChange}
          />
          <div ref={this.requestsListRef} className={`request-table-container${!showRequestColumn ? ' collapse' : ''}`}>
            <SchedulerRequestList
              requests={requests}
              hide={!showRequestColumn}
              onFilterValueChanged={this.handleOnRequestValueChange}
              onScrollEnd={this.handleOnRequestListScrollEnd}
              onRequestChanged={this.handleOnRequestChanged}
              updateLoadingState={this.updateLoadingState}
              requestsStore={this.props.requestsStore}
              optionsFromRequests={this.RequestDtosToSelectionItems(requests)}
            />
          </div>
          <i onClick={this.onToggleRequestHandler} className={`grid-splitter b-fa b-fa-angle-${showRequestColumn ? 'right' : 'left'}`}></i>
          {selectedEvent && (
            <EventForm
              readOnly={eventEdition}
              mode="ViewDetails"
              event={{ ...buildEventViewModel(selectedEvent), fromScheduler: true }}
              onClose={this.handleOnCloseFormView}
              fromRequestTab={false}
            />
          )}
          {eventPreview && (
            <EventForm
              mode={(eventPreview.requests || []).length <= 1 ? 'Preview' : 'Merge'}
              event={{ ...buildEventViewModel(eventPreview), fromScheduler: true }}
              onClose={this.handleOnCloseEventPreview}
              fromRequestTab={false}
            />
          )}
        </div>
      </div>
    );
  }
}
export default withTranslation()(PlannerLocationPage);
