import { withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { Component } from 'react';
import EventFormView from 'site/pages/events/event-form/event-form';
import { connect } from 'redux-scaffolding-ts';
import './event-tab-pane.less';
import { EventDto, EventsStore } from 'stores/events/events-store';
import { RequestFormStore } from 'stores/requests/request-form-store';
import { buildEventViewModel } from 'stores/events/event-form-store';

interface EventTabPaneProps extends WithTranslation {
  requestFormStore?: RequestFormStore;
  eventsStore?: EventsStore;
  fromRequestTab?: boolean;
}

export interface EventTabPaneState {
  openedEventForm: EventDto;
}

@connect(['requestFormStore', RequestFormStore], ['eventsStore', EventsStore])
class EventTabPAne extends Component<EventTabPaneProps, EventTabPaneState> {
  state: EventTabPaneState = {
    openedEventForm: null
  };

  onCloseEventForm = (): void => this.setState({ openedEventForm: null });

  handleOnEventClicked = (selectedEventId: string): void => {
    this.props.eventsStore.getById(selectedEventId).then(event => this.setState({ openedEventForm: event }));
  };

  public render() {
    const { t, fromRequestTab } = this.props;
    const { openedEventForm } = this.state;
    const { eventItem } = this.props.requestFormStore.state.item;

    return (
      <>
        <div className="request-event-form-requests-list">
          <p className="students-table__column-title">{t('Event ID')}</p>
          <p className="students-table__column-title">{t('Event Title')}</p>
          <p className="students-table__column-title">{t('Event Location')}</p>
          <p className="students-table__column-title text__transparent">X</p>

          <React.Fragment key={eventItem?.id + eventItem.title}>
            <div className="border-bottom-gray">
              <a className="clickable-icon" onClick={() => this.handleOnEventClicked(eventItem.id)}>
                {eventItem.friendlyId}
              </a>
            </div>

            <div className="border-bottom-gray">
              <p>{eventItem.title}</p>
            </div>

            <div className="border-bottom-gray">
              <p>
                {eventItem.eventDetails.location
                  ? eventItem.eventDetails.location.code !== 'XXXX' && eventItem.eventDetails.location.code !== 'XXXXX'
                    ? eventItem.eventDetails.location.location + ' - ' + eventItem.eventDetails.location.countryName
                    : eventItem.eventDetails.location.location
                  : ''}
              </p>
            </div>
            <div className="border-bottom-gray" />
          </React.Fragment>
        </div>

        {openedEventForm && (
          <EventFormView
            event={buildEventViewModel(openedEventForm)}
            mode="ViewDetails"
            onClose={() => this.onCloseEventForm()}
            fromRequestTab={fromRequestTab}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(EventTabPAne);
