import { withTranslation, WithTranslation } from 'react-i18next';
import React, { Component } from 'react';
import { EventFormMode } from '../event-form';

import PreviousStateButton from './previous-state-button';
import ValidateButton from './validate-button';
import CloseButton from './close-button';
import EditButton from './edit-button';
import CancelEventButton from './cancel-event-button';
import RequestChangesButton from './request-changes-button';
import NextButton from './next-state-button';
import CancelButton from './cancel-button';
import CreateEventButton from './create-event-button';
import EditButtonForPocs from './edit-button-for-pocs';

export type EventFormActions =
  | 'previous'
  | 'requestChanges'
  | 'cancelEvent'
  | 'edit'
  | 'save'
  | 'validate'
  | 'next'
  | 'close'
  | 'merge'
  | 'create'
  | 'cancel'
  | 'editForPocs';

interface ActionButtonsComponentProps extends WithTranslation {
  readOnly: boolean;
  readOnlyForPocs: boolean;
  mode: EventFormMode;
  onActionPerformed?: (actionName: EventFormActions, payload?: any) => void;
}

class ActionButtonsComponent extends Component<ActionButtonsComponentProps> {
  private handleOnActionPerformed = (actionName: EventFormActions, event?) => {
    this.props.onActionPerformed && this.props.onActionPerformed(actionName, event);
  };

  render() {
    const { t, mode, readOnly, readOnlyForPocs } = this.props;

    if (mode === 'ViewDetails') {
      return (
        <>
          <PreviousStateButton readOnly={readOnly} onPrevious={() => this.handleOnActionPerformed('previous')} />
          <div className="form__actions__button form__align-right-btns">
            <RequestChangesButton readOnly={readOnly} onRequestChangesSubmitted={() => this.handleOnActionPerformed('requestChanges')} />
            <CancelEventButton readOnly={readOnly} onEventCancelled={() => this.handleOnActionPerformed('cancel')} />
            <EditButton readOnly={readOnly} onEdit={this.handleOnActionPerformed} onSave={this.handleOnActionPerformed} />
            <EditButtonForPocs
              readOnly={readOnlyForPocs}
              onEdit={() => this.handleOnActionPerformed('editForPocs')}
              onSave={this.handleOnActionPerformed}
            />
            <ValidateButton readOnly={readOnly} onValidated={() => this.handleOnActionPerformed('validate')} />
            <NextButton readOnly={readOnly} onNext={() => this.handleOnActionPerformed('next')} />
            <CloseButton readOnly={readOnly} onClosed={() => this.handleOnActionPerformed('close')} />
          </div>
        </>
      );
    }

    return (
      <div className="form__actions__button form__align-right-btns">
        {mode === 'Merge' && (
          <>
            <CancelButton text={t('Cancel Merge')} onCancel={() => this.handleOnActionPerformed('cancel')} />
            <CreateEventButton text={t('Merge')} onCreatedEvent={event => this.handleOnActionPerformed('merge', event)} />
          </>
        )}
        {mode === 'Preview' && (
          <>
            <CancelButton text={t('Cancel')} onCancel={() => this.handleOnActionPerformed('cancel')} />
            <CreateEventButton text={'Create'} onCreatedEvent={event => this.handleOnActionPerformed('create', event)} />
          </>
        )}
      </div>
    );
  }
}
export default withTranslation()(ActionButtonsComponent);
