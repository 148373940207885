import React, { FC, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react';
import queryString from 'query-string';

import { RouteComponentProps } from 'react-router';
import { IdentityService, Roles } from 'services/identity-service';
import { container } from 'inversify.config';
import MRTheoreticalQBList from './mr-question-bank/mr-theoretical-question-bank-list';
import NMRTheoreticalQBList from './nmr-question-bank/nmr-theoretical-question-bank-list';
import TemplatesTabPane from './templates/templates-tab-pane';
import TnaTheoreticalTestList from './tna-theoretical-tests/tna-theoretical-test-list';
import LineSeparator from 'widgets/bussiness/line-separator';

export interface TheoreticalTestHomeProps extends RouteComponentProps, WithTranslation {}
export interface FeedbacksHomeState {
  activeIndex: number;
}

const TheoreticalTestHomePage: FC<TheoreticalTestHomeProps> = ({ t, match, history, location, staticContext }) => {
  const identityService: IdentityService = container.get(IdentityService);

  const routes: RouteComponentProps = {
    match,
    history,
    location,
    staticContext
  };

  const panes = [
    {
      menuItem: t('MR Question Bank'),
      render: () => (
        <Tab.Pane attached={false}>
          <MRTheoreticalQBList {...routes} />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('NMR Question Bank'),
      render: () => (
        <Tab.Pane attached={false}>
          <NMRTheoreticalQBList {...routes} />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('Templates'),
      render: () => (
        <Tab.Pane attached={false}>
          <TemplatesTabPane {...routes} />
        </Tab.Pane>
      )
    },
    {
      menuItem: t('Forms'),
      render: () => (
        <Tab.Pane attached={false}>
          <TnaTheoreticalTestList {...routes} />
        </Tab.Pane>
      )
    }
    // {
    //   menuItem: t('Forms'),
    //   render: () => (
    //     <Tab.Pane attached={false}>
    //       <TheoreticalTestList {...routes} />
    //     </Tab.Pane>
    //   )
    // }
  ];
  const getActiveIndex = (): number => {
    let values = queryString.parse(location.search);
    if (values == null || values.tab == null) values = match.params;

    if (values == null || values.tab == null) return 0;

    let v: string;
    if (values.tab.constructor === Array) v = values.tab[0];
    else v = values.tab as string;
    const n = Number.parseInt(v);
    if (!Number.isNaN(n) && Number.isInteger(n) && Number.isFinite(n) && n >= 0 && n < panes.length) return n;
    return 0;
  };

  const [activeIndex, setActiveIndex] = useState(getActiveIndex());

  const isInRoleAdmin = (): boolean => {
    return IdentityService.isAdminOrPowerInstructor(identityService.getUserInfo());
  };
  const isInRolePoC = (): boolean => {
    return identityService.isInRole(Roles.PoC);
  };

  const onTabChange = (_, { activeIndex }) => {
    history.replace(`/assessments/theoretical-test?tab=${activeIndex}`);
    setActiveIndex(activeIndex);
  };

  return (
    <>
      {isInRoleAdmin() && (
        <>
          <h3 id="feedback-forms-list-filters__title">{t('Theoretical Test')}</h3>
          <Tab
            className="main-tab"
            menu={{ secondary: true, pointing: true }}
            panes={panes}
            activeIndex={activeIndex}
            onTabChange={onTabChange}
          />
        </>
      )}
      {isInRolePoC() && (
        <>
          <h3 id="feedback-forms-list-filters__title">{t('TNA Theoretical Test')}</h3>
          <LineSeparator />
          <TnaTheoreticalTestList {...routes} />
        </>
      )}
    </>
  );
};

export default withTranslation()(TheoreticalTestHomePage);
