import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Popup, Menu, Icon } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RequestDto, RequestsStore } from 'stores/requests/requests-store';
import RequestFormView, { RequestFormActions } from '../../../requests/request-form/request-form';
import { buildRequestFormViewModel } from 'stores/requests/request-form-store';

export interface RequestItemMenuProps extends WithTranslation {
  request: RequestDto;
  onRequestChanged?: (request?: RequestDto, action?: RequestFormActions) => void;
  requestsStore?: RequestsStore;
  updateLoadingState?: (isLoadind: boolean) => void;
}

const RequestItemMenu: React.FC<RequestItemMenuProps> = props => {
  const [state, dispatch] = React.useReducer((state, newState) => ({ ...state, ...newState }), { open: false, showRequestDetails: false });
  const [requestDetailDto, setRequestDetailDto] = React.useState(null);

  const { t, request, onRequestChanged, requestsStore, updateLoadingState } = props;

  const handleOnViewDetailsClick = () => {
    updateLoadingState && updateLoadingState(true);
    dispatch({ open: false });
    requestsStore.getById(request.id).then(request => {
      setRequestDetailDto(request);
      dispatch({ showRequestDetails: true });
      updateLoadingState && updateLoadingState(false);
    });
  };

  const onRequestDetailsClose = (request?: RequestDto, action?: RequestFormActions) => {
    dispatch({ showRequestDetails: false });
    onRequestChanged(request, action);
  };

  return (
    <>
      <Popup
        position="bottom right"
        trigger={
          <div className="request-component__options-detail" onClick={() => dispatch({ open: true })}>
            <FontAwesomeIcon icon={faEllipsisV} />
          </div>
        }
        open={state.open}
        on="click"
        size="mini"
        closeOnTriggerBlur
        onClose={() => dispatch({ open: false })}
      >
        <Menu vertical secondary>
          <Menu.Item key="viewdetails" position="left" onClick={handleOnViewDetailsClick}>
            <Icon name="edit" style={{ color: '#474b4f' }} />
            {t('View Details')}
          </Menu.Item>
        </Menu>
      </Popup>
      {state.showRequestDetails && (
        <RequestFormView request={buildRequestFormViewModel(requestDetailDto)} onClose={onRequestDetailsClose} fromEventTab={false} />
      )}
    </>
  );
};

RequestItemMenu.defaultProps = { onRequestChanged: () => {} };

export default withTranslation()(RequestItemMenu);
