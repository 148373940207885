import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Modal, Button, Icon, Input, Dimmer, Loader } from 'semantic-ui-react';
import RequestFormTabMenu from './tabs/request-form-tab-menu';
import { RequestDto, ChangeRequestDto } from 'stores/requests/requests-store';
import { RequestFormStore, RequestFormViewModel } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import '../../shared/events-and-requests/events-requests-form.less';
import { History } from 'history';
import { Message } from 'stores/types';
import { isExtendedWorkflow } from 'utils/event-type-utils';
import { RequestChatStore } from 'stores/requests/requests-chat-stores';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import RequestCloneButton from './buttons/request-clone-button';

export type RequestCloneFormActions = 'close' | 'cancel' | 'edit' | 'accept' | 'reject' | 'send' | 'reopen' | 'save';

export interface RequestCloneFormProps extends WithTranslation {
  onClose?: (request?: RequestDto, action?: RequestCloneFormActions) => void;
  request: RequestFormViewModel;
  requestFormStore?: RequestFormStore;
  requestChatStore?: RequestChatStore;
  history?: History;
}

export interface RequestCloneFormState {
  readOnly: boolean;
  loading: boolean;
  errors: Message[];
  saveDone: boolean;
}

@connect(['requestFormStore', RequestFormStore], ['requestChatStore', RequestChatStore])
class RequestCloneForm extends Component<RequestCloneFormProps, RequestCloneFormState> {
  state: RequestCloneFormState = {
    readOnly: true,
    loading: false,
    errors: [],
    saveDone: false
  };

  constructor(props: Readonly<RequestCloneFormProps>) {
    super(props);
    this.props.requestFormStore.change({ ...this.props.request });
  }

  componentWillUnmount() {
    this.props.requestFormStore.reset();
    this.props.requestChatStore.reset();
  }

  private handleOnCloseModal = (request?: RequestDto, actionName?: RequestCloneFormActions) => {
    if (this.props.onClose) {
      const action = this.replaceActionOnClose(actionName);
      this.props.onClose(request, action);
    }

    if (this.props.history) this.props.history.replace('/requests');
  };

  private replaceActionOnClose = (actionName?: RequestCloneFormActions): RequestCloneFormActions => {
    let action: RequestCloneFormActions = actionName;
    if (action === 'close' && this.state.saveDone) {
      action = 'save';
    }
    return action;
  };

  private changeToEventCreated = (request: RequestDto = null) => {
    if (this.props.history && request && request.eventCreatedId && !isExtendedWorkflow(request.eventType.eventTypeCategory))
      this.props.history.replace(`/events/${request.eventCreatedId}`);
    else {
      this.setState({ loading: false });
      this.handleOnCloseModal(request);
    }
  };

  private handleOnRequestChange = (property: keyof ChangeRequestDto, value: any) => {
    let changes = {};
    changes[property] = value;

    this.props.requestFormStore.change(changes);
  };

  private handleOnSave = () => {
    this.setState({ loading: true });
    this.props.requestFormStore
      .updateRequest()
      .then(result => {
        if (result?.isSuccess) {
          this.setState({ readOnly: result.isSuccess, loading: false, saveDone: true });
          ToastComponent({ text: 'Request saved successfully!', type: 'success-toast' });
        } else this.setState({ loading: false });
      })
      .catch(err => {
        console.error(err);
        (this.props.requestFormStore.state.result?.messages || []).forEach(msg =>
          ToastComponent({ text: msg.body, type: 'warning-toast' })
        );
        this.setState({ loading: false });
      });
  };

  private handleOnClone = () => {
    this.setState({ loading: true });
    this.props.requestFormStore
      .cloneRequest()
      .then(result => {
        if (result?.isSuccess) {
          this.setState({ readOnly: result.isSuccess, loading: false, saveDone: true });
          ToastComponent({ text: 'Request cloned successfully!', type: 'success-toast' });
          this.props.onClose();
        } else this.setState({ loading: false });
      })
      .catch(err => {
        console.error(err);
        (this.props.requestFormStore.state.result?.messages || []).forEach(msg =>
          ToastComponent({ text: msg.body, type: 'warning-toast' })
        );
        this.setState({ loading: false });
      });
  };

  private handleShowToastReopened = (request: RequestDto) => {
    const { t } = this.props;
    this.setState({ loading: false });
    ToastComponent({ type: 'success-toast', text: t('Form successfully reopened!') });
    // //requestFormStore.change(buildRequestFormViewModel(request));
    // requestFormStore.change(buildRequestCloneFormViewModel(request));
  };

  render() {
    const { requestFormStore, t } = this.props;
    const request = requestFormStore.state.item;
    const { readOnly, loading, errors } = this.state;
    if (!request) return null;

    return (
      <>
        <Modal
          className="planit-generic-form__modal events-form__modal"
          open
          closeOnEscape={true}
          onClose={() => this.handleOnCloseModal(null, 'close')}
          closeOnDimmerClick={false}
        >
          <Dimmer active={loading} style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Loading')}</Loader>
          </Dimmer>
          <div className="form__modal__left-container">
            <div className="form__modal__close-menu-left-container">
              <div className="form__header-top-container">
                <Button className="form__modal__close-btn" icon onClick={() => this.handleOnCloseModal(null, 'close')}>
                  <Icon name="times" />
                </Button>

                <div className="form__header-title-status">
                  <div className="flex-start-center">
                    <Input className="form__header__title-input__friendlyId" readOnly value={`${request.friendlyId}   - `} />
                    <Input
                      className="form__header__title-input"
                      readOnly={readOnly}
                      value={request.title}
                      onChange={(_, { value }) => this.handleOnRequestChange('title', value)}
                    />
                  </div>
                </div>
              </div>

              <RequestFormTabMenu extraErrors={errors} readOnly={false} fromEventTab={false} />

              <Modal.Actions className="form__action-buttons-wrapper">
                <div className="form__align-right-btns">
                  <RequestCloneButton
                    requestStatus={request.status}
                    requestCreatorRoles={request.rolesThatCreateRequests}
                    isGea={request.isGEA}
                    isGma={request.isGMA}
                    extendedStatusValidation={request.extendedStatusValidation}
                    onClone={this.handleOnClone}
                  />
                </div>
              </Modal.Actions>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(RequestCloneForm);
