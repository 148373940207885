import { withTranslation, WithTranslation } from 'react-i18next';
import * as React from 'react';
import { Icon } from 'semantic-ui-react';
import { Component } from 'react';
import { RequestDto, RequestsStore } from 'stores/requests/requests-store';
import RequestFormView from 'site/pages/requests/request-form/request-form';
import { connect } from 'redux-scaffolding-ts';
import './requests-tab-pane.less';
import { buildRequestFormViewModel } from 'stores/requests/request-form-store';
import { EventFormStore } from 'stores/events/event-form-store';
import { ChangeEventsStore } from 'stores/events/events-store';
import CancelEventPopup from '../../popups/cancel-event-popup/cancel-event-popup';

interface RequestsTabPaneProps extends WithTranslation {
  readOnly: boolean;
  requestsStore?: RequestsStore;
  eventFromStore?: EventFormStore;
  changeEventStore?: ChangeEventsStore;
  fromEventTab: boolean;
}

export interface RequestsTabPaneState {
  openedRequestForm: RequestDto;
  showRemovalRequestEventPopup: boolean;
  removedRequest: RequestDto;
}

@connect(['requestsStore', RequestsStore], ['eventFromStore', EventFormStore])
class RequestsTabPane extends Component<RequestsTabPaneProps, RequestsTabPaneState> {
  state: RequestsTabPaneState = {
    openedRequestForm: null,
    showRemovalRequestEventPopup: false,
    removedRequest: null
  };

  onCloseRequestForm = (): void => this.setState({ openedRequestForm: null });

  handleOnRequestClicked = (selectedRequestId: string): void => {
    this.props.requestsStore.getById(selectedRequestId).then(request => this.setState({ openedRequestForm: request }));
  };

  handleOnRemoveRequestClick = (removedRequest: RequestDto) => this.setState({ showRemovalRequestEventPopup: true, removedRequest });

  private handleOnChangeEventRequest = (request: RequestDto): void => {
    const { item: event } = this.props.eventFromStore.state;

    this.props.eventFromStore.removeRequestFromEvent(request.id);

    const requests = event.requests.filter(x => request.id !== x.id);

    const currentRequestsStudentsIds = requests
      .map(x => x.students)
      .flat()
      .map(s => s.id);

    const removeRequestStudentsIds = event.requests.firstOrDefault(x => request.id === x.id).students.map(s => s.id);

    const studentToRemoveIds = removeRequestStudentsIds.filter(x => !currentRequestsStudentsIds.includes(x));
    const students = event.students.filter(({ id }) => !studentToRemoveIds.includes(id));
    const studentsRequested = event.studentsRequested - request.studentsNumber;

    this.props.eventFromStore.change({ requests, students, studentsRequested, studentsAssigned: students.length });

    this.setState({ showRemovalRequestEventPopup: false, removedRequest: null });
  };

  onCancelOrError = () => this.setState({ showRemovalRequestEventPopup: false, removedRequest: null });

  public render() {
    const { removedRequest, showRemovalRequestEventPopup, openedRequestForm } = this.state;
    const { t, readOnly, fromEventTab } = this.props;
    const { requests, parentRequest } = this.props.eventFromStore.state.item;

    return (
      <>
        <div className="events-form-requests-list">
          <p className="students-table__column-title">{t('Request ID')}</p>
          <p className="students-table__column-title">{t('Request Title')}</p>
          <p className="students-table__column-title">{t('Requesting Location')}</p>
          <p className="students-table__column-title">{t('Request Creator')}</p>
          <p className="students-table__column-title text__transparent">X</p>

          {(requests || []).map(request => {
            return (
              <React.Fragment key={request?.id + request.title}>
                <div className="border-bottom-gray">
                  <a className="clickable-icon" onClick={() => this.handleOnRequestClicked(request.id)}>
                    {request.friendlyId}
                  </a>
                </div>

                <div className="border-bottom-gray">
                  <p>{request.title}</p>
                </div>

                <div className="border-bottom-gray">
                  <p>
                    {request.requestingLocationName
                      ? request.requestLocation.code !== 'XXXX' && request.requestLocation.code !== 'XXXXX'
                        ? request.requestingLocationName + ' - ' + request.requestingLocationCountryName
                        : request.requestingLocationName
                      : ''}
                  </p>
                </div>

                <div className="border-bottom-gray">
                  <p>{request.requestOwner && `${request.requestOwner.lastName}, ${request.requestOwner.firstName}`}</p>
                </div>

                {!readOnly && parentRequest.id !== request.id ? (
                  <div className="border-bottom-gray flex-center">
                    <Icon
                      className="clickable-icon"
                      size="large"
                      color="red"
                      name="remove"
                      onClick={() => this.handleOnRemoveRequestClick(request)}
                    />
                  </div>
                ) : (
                  <div className="border-bottom-gray" />
                )}
              </React.Fragment>
            );
          })}
        </div>

        {openedRequestForm && (
          <RequestFormView
            request={buildRequestFormViewModel(openedRequestForm)}
            onClose={() => this.onCloseRequestForm()}
            fromEventTab={fromEventTab}
          />
        )}
        {removedRequest && (
          <CancelEventPopup
            cancelRequest
            request={removedRequest}
            open={showRemovalRequestEventPopup}
            onSuccess={() => this.handleOnChangeEventRequest(removedRequest)}
            onCancel={this.onCancelOrError}
            onError={this.onCancelOrError}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(RequestsTabPane);
