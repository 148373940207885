import { ItemResult } from 'stores/types';
import { repository, ReduxRepository, AsyncAction } from 'redux-scaffolding-ts';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { AxiosResponse } from 'axios';
import { ToastComponent } from 'site/pages/landing-pages/util/toast-component';
import i18n from 'i18n';

interface TheoreticalTestFormsDownloaderState {
  isBusy: boolean;
  result: ItemResult<any>;
}

@repository('@@THEORETICALTESTFORMS', 'theoreticaltestforms.download')
export class DownloadTheoreticalTestFormsStore extends ReduxRepository<TheoreticalTestFormsDownloaderState> {
  baseUrl = 'events';
  initBackgroundDownloadUrl = 'v1/download-theoretical-forms-in-background';

  public BACKGROUND_DOWNLOAD_INITIALIZED = 'BACKGROUND_DOWNLOAD_INITIALIZED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.onBackgroundDownloadInitialized.bind(this);

    this.addReducer(this.BACKGROUND_DOWNLOAD_INITIALIZED, this.onBackgroundDownloadInitialized, 'AsyncAction');
  }

  public async initBackgroundDownload() {
    const url = `${this.baseUrl}/${this.initBackgroundDownloadUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.BACKGROUND_DOWNLOAD_INITIALIZED, httpService.post(url, null));
  }

  private onBackgroundDownloadInitialized = (): AsyncAction<AxiosResponse<any>, TheoreticalTestFormsDownloaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: result => {
        ToastComponent({ text: i18n.t('The email has been sent'), type: 'success-toast' });
        return { ...this.state, isBusy: false, result: result.data };
      },
      onError: error => {
        ToastComponent({ text: i18n.t('File export failed'), type: 'error-toast' });
        return { ...this.state, isBusy: false, result: error };
      }
    };
  };
}
