import React, { FC, memo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { GeneratedAtStatus, GeneratedAtStatuses, getAllGeneratedAtStatuses } from 'stores/assessments/forms/theoretical-form-test-store';

interface GeneratedAtStatusFormEditorProps extends WithTranslation {
  value: GeneratedAtStatuses | GeneratedAtStatus;
  onChange?: (value: GeneratedAtStatuses) => void;
  placeholder?: string;
  className?: string;
  clearable?: boolean;
  readOnly?: boolean;
  direction?: 'left' | 'right';
  onBlur?: (event: any) => void;
  onFocus?: (event: any) => void;
}
const GeneratedAtStatusFormEditor: FC<GeneratedAtStatusFormEditorProps> = ({ t, onChange, ...props }) => {
  const { direction, value, className, placeholder, readOnly, clearable } = props;
  const [generatedAtStatuses] = useState<DropdownItemProps[]>(getAllGeneratedAtStatuses);
  return (
    <Dropdown
      direction={direction}
      placeholder={t(placeholder)}
      className={className}
      selection
      selectOnBlur={false}
      options={generatedAtStatuses}
      disabled={readOnly}
      value={value === 'InProgress' || value === 'Completed' ? GeneratedAtStatuses[value] : value}
      onChange={(_, { value }) => onChange(value ? GeneratedAtStatuses[value as string] : value)}
      clearable={clearable}
      onBlur={e => props.onBlur && props.onBlur(e)}
      onFocus={e => props.onFocus && props.onFocus(e)}
    />
  );
};

GeneratedAtStatusFormEditor.defaultProps = {
  clearable: false,
  onChange: () => {},
  placeholder: 'Select Generated At Status',
  className: 'user-dropdown'
};

export default withTranslation()(memo(GeneratedAtStatusFormEditor));
