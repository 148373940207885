import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Form, Button, Modal, Grid, Dimmer, Loader } from 'semantic-ui-react';
import { OrderDefinition, Query, ItemReference } from 'stores/dataStore';
import { getProperties } from 'utils/object';
import { TableModel, TableView, TableColumn, TablePagination } from 'widgets/collections/table';
import { nameof } from 'utils/object';
import { isNullOrWhiteSpaces, openInNewWindow, extractFriendlyIdNumber, trim } from 'utils/useful-functions';
import MultipleLocationEditor from 'widgets/bussiness/multiple-selector/multiple-location-editor';
import { resolve } from 'inversify.config';
import { IdentityService } from 'services/identity-service';
import { TnaFormListDto } from 'stores/assessments/forms/tna-forms-store';
import { ClearableTimerInput } from 'widgets/editors/clearable-timer-input';
import { ProfileItemDto } from 'stores/profile/profile-store';
import { LocationDto } from 'stores/configuration/locations/locations-store';

import {
  GeneratedAtStatus,
  SimpleTheoreticalFormAnswerDto,
  TheoreticalFormDto,
  TheoreticalTestListStore
} from 'stores/assessments/forms/theoretical-form-test-store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { DownloadTheoreticalTestFormsStore } from 'stores/assessments/forms/theoretical-test-forms-upload-store';
import MultiEventTypeEditor from 'widgets/bussiness/multiple-selector/multi-event-type-editor';
import GeneratedAtStatusFormEditor from 'widgets/bussiness/generatedatstatus-form-editor';

export interface TnaTheoreticalTestListProps extends WithTranslation, RouteComponentProps {
  tnaTheoreticalTestListStore?: TheoreticalTestListStore;
  downloadTheoreticalTestStore: DownloadTheoreticalTestFormsStore;
}

export interface TnaTheoreticalTestListState {
  query: Query;
  activeFilters: { [key: string]: any };
  selectedItem: TheoreticalFormDto;
  locations: LocationDto[];
  eventTypes: ItemReference[];
  creationDateFrom: string;
  creationDateTo: string;
  deadlineFrom: string;
  deadlineTo: string;
  profile: ProfileItemDto;
  cluster: ItemReference;
  equipmentType: ItemReference;
  oem: ItemReference;
  machineModel: ItemReference;
  showDisabled: boolean;
  employee: string;
  showDeleteConfirmModal: boolean;
  someFilterOpened: boolean;
  showDownloadMsg: boolean;
  pagination: TablePagination;
  togglePagination: boolean;
  generatedAtStatus: GeneratedAtStatus;
}

@connect(['tnaTheoreticalTestListStore', TheoreticalTestListStore], ['downloadTheoreticalTestStore', DownloadTheoreticalTestFormsStore])
class TnaTheoreticalTestList extends React.Component<TnaTheoreticalTestListProps, TnaTheoreticalTestListState> {
  constructor(props) {
    super(props);
    let pagination: TablePagination = null;

    this.state = {
      activeFilters: [],
      selectedItem: null,
      togglePagination: false,
      pagination: pagination,

      query: {
        searchQuery: '',
        orderBy: [{ direction: 'Descending', field: nameof<TnaFormListDto>('createdOn'), useProfile: false }],
        skip: 0,
        take: 10,
        parameters: {}
      },

      showDownloadMsg: false,
      locations: [],
      eventTypes: [],
      creationDateFrom: null,
      creationDateTo: null,
      deadlineFrom: null,
      deadlineTo: null,
      profile: null,
      cluster: null,
      equipmentType: null,
      oem: null,
      machineModel: null,
      showDisabled: false,
      employee: '',
      showDeleteConfirmModal: false,
      someFilterOpened: false,
      generatedAtStatus: null
    };
  }

  componentDidMount() {
    this.load();
  }

  @resolve(IdentityService)
  private identityService: IdentityService;

  handleOnEnterKeydown = item => {
    this.openTnaTheoreticalTest(item);
  };

  handleOnRowDoubleClick = item => {
    this.openTnaTheoreticalTest(item);
  };

  private onOpenEmployee = (user: TheoreticalFormDto) => {
    openInNewWindow(`./employee-page/${user.userId}/${user.id}`);
  };

  private load = () => {
    const activeFilters = { ...this.state.activeFilters };
    const { togglePagination } = this.state;

    let filter = getProperties(activeFilters)
      .filter(({ value }) => !!value)
      .map(({ value }) => value);
    //filter = [{ TypeRelated: 'TNA' }, ...filter];

    let orderBy = [...this.state.query.orderBy];
    let initPagination = !togglePagination && filter.length > 0;

    this.setState(
      ({ query, pagination }) => {
        const newQuery = { ...query, filter, orderBy };
        const newPagination = { ...pagination };
        if (initPagination) {
          newQuery.skip = 0;
          newPagination.activePage = 1;
        }

        return {
          query: newQuery,
          pagination: newPagination,
          togglePagination: false,
          showDownloadMsg: false
        };
      },
      () => this.props.tnaTheoreticalTestListStore.getAllAsync(this.state.query)
    );
  };

  private handleOrderBy = (newOrder: OrderDefinition[]) => {
    const orderBy: OrderDefinition[] = [
      ...newOrder,
      { direction: 'Descending', field: nameof<TnaFormListDto>('createdOn'), useProfile: false }
    ];

    this.setState(({ query }) => ({ query: { ...query, orderBy } }), this.load);
  };

  private handlePageChange = (skip: number, take: number) => {
    const pagination: TablePagination = {
      ...(null as TablePagination),
      activePage: skip / take + 1,
      pageSize: take
    };
    this.setState(
      ({ query }) => ({
        togglePagination: true,
        query: { ...query, skip, take },
        pagination
      }),
      this.load
    );
  };

  private onDelete = async (id: string) => {
    this.hideConfirmModal();
    await this.props.tnaTheoreticalTestListStore.deleteAsync(id);
  };

  private showDeleteConfirmationModal = (selectedItem: TheoreticalFormDto) => {
    this.setState({ selectedItem, showDeleteConfirmModal: true });
  };

  private hideConfirmModal = () => {
    this.setState({ showDeleteConfirmModal: false, selectedItem: null });
  };

  private openTnaTheoreticalTest = (selectedForm: TheoreticalFormDto) => {
    this.setState({ selectedItem: selectedForm });
    openInNewWindow(`./assessments/theoretical-test/${selectedForm.id}`);
  };

  private handleFilterTitleorID = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) delete activeFilters['IdDescription'];
    else {
      const input = value as string;
      const parts = [`contains(tolower(TnaDetails/Title), '${input.toLowerCase()}')`];

      const friendlyId = extractFriendlyIdNumber(input, 'The');
      if (!Number.isNaN(friendlyId)) {
        if (input.startsWith('The')) parts.push(`cast(FriendlyId, 'Edm.String') eq '${friendlyId}'`);
        else parts.push(`contains(cast(FriendlyId, 'Edm.String'), '${friendlyId !== 0 ? friendlyId : input}')`);
      }

      activeFilters['IdDescription'] = `(${parts.join(' or ')})`;
    }

    this.setState(({ query }) => ({ query: { ...query, skip: 0 }, activeFilters }), this.load);
  };

  private handleFilterFriendlyId = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['FriendlyIdFilter'];
    } else {
      const friendlyId = extractFriendlyIdNumber(value, 'E');

      if (!Number.isNaN(friendlyId)) {
        activeFilters['FriendlyIdFilter'] = `cast(EventDetails/Event/FriendlyEventId, 'Edm.String') eq '${friendlyId}'`;
      } else {
        delete activeFilters['FriendlyIdFilter'];
      }
    }

    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEvent = (_, { value }) => {
    const activeFilters = { ...this.state.activeFilters };

    if (isNullOrWhiteSpaces(value)) {
      delete activeFilters['IdEventTitle'];
    } else {
      const input = value as string;
      const parts = [`contains(tolower(EventDetails/Event/Title), '${input.toLowerCase()}')`];

      activeFilters['IdEventTitle'] = `(${parts.join(' or ')})`;
    }

    this.setState({ activeFilters }, this.load);
  };

  private onEventTypeFilterChanged = (eventTypes: ItemReference[], fieldName: string) => {
    const activeFilters = { ...this.state.activeFilters };

    if (!eventTypes || eventTypes.length <= 0) {
      delete activeFilters['IdEventType'];
    } else {
      let parts = [];
      eventTypes.map(eventType => {
        parts.push([`contains(tolower(${fieldName}), '${eventType.title.toLowerCase()}')`]);
        return null;
      });
      activeFilters['IdEventType'] = `(${parts.join(' or ')})`;
    }
    this.setState({ activeFilters }, this.load);
  };

  private handleFilterEmployee = (employee: string) => {
    const parameters = { ...(this.state.query?.parameters || {}) };

    if (isNullOrWhiteSpaces(employee)) delete parameters['EmployeeOrSfPosition'];
    else parameters['EmployeeOrSfPosition'] = employee;

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, employee }), this.load);
  };

  private handleFilterLocations = (locations: LocationDto[]) => {
    const parameters = { ...(this.state.query?.parameters || {}) };

    if ((locations || []).length !== 0) parameters['LocationId'] = locations.map(x => x.id);
    else delete parameters['LocationId'];

    this.setState(({ query }) => ({ query: { ...query, skip: 0, parameters }, locations }), this.load);
  };

  private handleFilterPreOrPostEventTests = (fieldName, value) => {
    this.setState({ generatedAtStatus: value });
    const activeFilters = { ...this.state.activeFilters };

    if (!value) {
      delete activeFilters[fieldName];
      this.setState({ generatedAtStatus: null });
    } else {
      activeFilters[fieldName] = { 'EventDetails/GeneratedAtStatus': { eq: value } };
    }
    this.setState({ activeFilters }, this.load);
  };

  private getStatus = (item: TheoreticalFormDto): 'Done' | 'Out of Date' | 'New' => {
    if (item.finishedAt != null) return 'Done';
    if (item.tnaDetails.outOfDate) return 'Out of Date';
    return 'New';
  };

  private handleDownloadMsgDismiss = () => {
    this.setState({ showDownloadMsg: false });
  };

  private initExportTheoreticalTestProcess = () => {
    this.setState({ showDownloadMsg: true });

    this.props.downloadTheoreticalTestStore.initBackgroundDownload();
  };

  private getBackgroundDownloadMessage = () => {
    const { isBusy, result } = this.props.downloadTheoreticalTestStore.state;
    const { t } = this.props;
    if (isBusy || result == null) return null;
    let header = '';
    let content = '';
    if (!result.isSuccess) {
      header = t('Something went wrong');
      if (Array.isArray(result.messages)) {
        content = (result.messages || []).map(x => x.body).join('. ');
      }
    } else {
      header = t('Background process initialized successfully');
      content = t('You will receive an email when the process ends');
    }
    return (
      <Message
        className={!result.isSuccess && 'error-message__style'}
        icon={!result.isSuccess && 'exclamation circle'}
        onDismiss={this.handleDownloadMsgDismiss}
        header={header}
        content={content}
        error={!result.isSuccess}
        success={result.isSuccess}
      />
    );
  };

  private showCorrectlyAnswered(answerDetails: SimpleTheoreticalFormAnswerDto): string {
    if (answerDetails.answered === true) {
      return `${answerDetails.correctAnswered > 0 ? answerDetails.correctAnswered : 0}`;
    }
    return '-';
  }

  private showQuestionsAnswered(answerDetails: SimpleTheoreticalFormAnswerDto): string {
    if (answerDetails.answered === true) {
      return `${answerDetails.questionsAnswered > 0 ? answerDetails.questionsAnswered : 0}`;
    }
    return '-';
  }

  private showResultDetails(answerDetails: SimpleTheoreticalFormAnswerDto): string {
    if (answerDetails.answered === true) {
      return `${answerDetails.result}%`;
    }
    return '-';
  }

  private getColumnsModel = (): TableColumn<TheoreticalFormDto>[] => {
    const { t } = this.props;

    let columns: TableColumn<TheoreticalFormDto>[] = [
      {
        title: t('Theoretical ID'),
        tooltipRenderer: true,
        renderer: data => data?.friendlyId,
        selectableHeader: true,
        sortDefinition: {
          field: nameof<TheoreticalFormDto>('friendlyId'),
          useProfile: false
        }
      },
      {
        title: t('Theoretical Title'),
        tooltipRenderer: true,
        renderer: data => (data?.typeRelated === 'TNA' ? data?.tnaDetails?.title : ''),
        selectableHeader: true
      },
      {
        title: t('Event Id'),
        tooltipRenderer: true,
        renderer: data => (data?.typeRelated === 'Event' ? data?.eventDetails?.friendlyId : ''),
        selectableHeader: true
      },
      {
        title: t('Event'),
        tooltipRenderer: true,
        renderer: data => (data?.typeRelated === 'Event' ? data?.eventDetails?.title : ''),
        selectableHeader: true
      },
      {
        title: t('Event Type'),
        tooltipRenderer: true,
        renderer: data => (data?.typeRelated === 'Event' ? data?.eventDetails?.eventType : ''),
        selectableHeader: true
      },
      {
        title: t('Employee'),
        tooltipRenderer: false,
        renderer: data => trim(`${data?.user?.lastName}, ${data?.user?.firstName}`.trim(), ','),
        selectableHeader: true
      },
      { title: t('SAP ID'), tooltipRenderer: true, renderer: data => data?.user?.employeeId, selectableHeader: true },
      { title: t('SF Position'), tooltipRenderer: true, renderer: data => data?.user?.sfPosition, selectableHeader: true },
      { title: t('Location'), tooltipRenderer: true, renderer: data => data?.user?.location?.location, selectableHeader: true },
      {
        title: t('Role'), ///todo: if required for both at same time, use data.template
        tooltipRenderer: true,
        renderer: data => (
          <span className="assessment-forms-grey-tag">
            {data?.typeRelated === 'Event' ? data?.eventDetails?.profession : data?.tnaDetails?.profileName}
          </span>
        ),
        selectableHeader: true
      },
      {
        title: t('Generated At Status'),
        tooltipRenderer: true,
        renderer: data => (data?.typeRelated === 'Event' ? data?.eventDetails?.generatedAtStatus : '-'),
        selectableHeader: true
      },
      {
        title: t('Correct Answered'),
        tooltipRenderer: true,
        renderer: data => <span>{data.answerDetails ? this.showCorrectlyAnswered(data.answerDetails) : '-'}</span>,
        selectableHeader: true
      },
      {
        title: t('Questions Answered'),
        tooltipRenderer: true,
        renderer: data => <span>{data.answerDetails ? this.showQuestionsAnswered(data.answerDetails) : '-'}</span>,
        selectableHeader: true
      },
      {
        title: t('Total Questions'),
        tooltipRenderer: true,
        renderer: data => <span>{data?.answerDetails?.totalQuestions || '-'}</span>,
        selectableHeader: true
      },
      {
        title: t('Result'),
        tooltipRenderer: true,
        renderer: data => <span>{data.answerDetails ? this.showResultDetails(data.answerDetails) : '-'}</span>,
        selectableHeader: true
      }
    ];
    return columns;
  };

  private getExtraActions = () => {
    const { t } = this.props;

    let buttonMenuOptions = [
      {
        content: (
          <>
            <Icon name="file alternate" />
            {<span className="text__bold">{t('Open Theoretical Form')}</span>}
          </>
        ),
        onClick: this.openTnaTheoreticalTest
      },
      {
        content: (
          <>
            <>
              <Icon name="eye" />
              {<span className="text__bold">{t('Open Employee Card')}</span>}
            </>
          </>
        ),
        onClick: (item: TheoreticalFormDto) => {
          this.setState({ selectedItem: item }, () => this.onOpenEmployee(item));
        }
      },
      {
        content: (
          <>
            <Icon color="red" name="trash alternate" />
            {<span className="text__red text__bold">{t('Delete')}</span>}
          </>
        ),
        onClick: this.showDeleteConfirmationModal,

        isVisible: (item: TheoreticalFormDto) => {
          if (item.typeRelated === 'TNA') return this.getStatus(item) === 'New' || this.getStatus(item) === 'Out of Date';
          else return true;
        }
      }
    ];
    return buttonMenuOptions;
  };

  onBlurHandler = () => {
    this.setState({ someFilterOpened: false });
  };

  onFocusHandler = () => {
    this.setState({ someFilterOpened: true });
  };

  public render() {
    const { t, tnaTheoreticalTestListStore } = this.props as any;
    const { state: storeState } = tnaTheoreticalTestListStore;
    const { showDeleteConfirmModal, selectedItem, employee, locations, eventTypes, someFilterOpened, pagination } = this.state;

    const pocLocations = IdentityService.isPoc(this.identityService.getUserInfo())
      ? (this.identityService.getUserInfo().locationsByRoles['PoC'] as string[]) || []
      : [];

    const tableModel = {
      columns: this.getColumnsModel(),
      data: storeState,
      pagination
    } as TableModel<TheoreticalFormDto>;

    return (
      <div className="question-bank__wrapper tna-theoretical__wrapper">
        {this.props.downloadTheoreticalTestStore.state.isBusy && (
          <Dimmer active style={{ zIndex: 999, background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Initializing download background process')}</Loader>
          </Dimmer>
        )}
        {this.state.showDownloadMsg && this.getBackgroundDownloadMessage()}

        <Grid className="event-types-list-grid">
          {storeState.result && !storeState.result.isSuccess && (storeState.result?.messages || []).length > 0 && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                list={storeState.result.messages.map(o => o.body)}
                onDismiss={tnaTheoreticalTestListStore.clearMessages}
              />
            </Grid.Row>
          )}

          <div id="assessment-forms-list-filters__admin-or-poc-container">
            <div id="assessment-forms-list-filters__admin-or-poc-container__lef-container" className="specific-margin-top-equal-filters">
              <div className="above-row row">
                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search in Title or ID')}
                    onChange={this.handleFilterTitleorID}
                    key={'searchTna'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Event ID')}
                    onChange={this.handleFilterFriendlyId}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Event')}
                    onChange={this.handleFilterEvent}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <MultiEventTypeEditor
                    schedulerRender
                    placeholder={t('Event Type')}
                    useOriginalEventTypeIdAsValue
                    value={eventTypes.map(eventTypeItem => eventTypeItem.id)}
                    onChange={(_, items) => this.onEventTypeFilterChanged(items, 'EventDetails/Event/EventTypeItem/Name')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <ClearableTimerInput
                    icon="search"
                    placeholder={t('Search Employee')}
                    onChange={(e, employee) => this.handleFilterEmployee(employee.value)}
                    value={employee || ''}
                    key={'searchEmployee'}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <MultipleLocationEditor
                    placeholder={t('Locations')}
                    clearable
                    locationsReceived={pocLocations}
                    value={locations}
                    onChange={this.handleFilterLocations}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>

                <Form.Field>
                  <GeneratedAtStatusFormEditor
                    readOnly={false}
                    className="filter margin-top-0 request__title-input"
                    value={this.state.generatedAtStatus}
                    onChange={value => this.handleFilterPreOrPostEventTests('generatedAtStatus', value)}
                    clearable
                    placeholder={t('Generated At Status')}
                    onBlur={this.onBlurHandler}
                    onFocus={this.onFocusHandler}
                  />
                </Form.Field>
              </div>
            </div>
          </div>
          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              selectable={!showDeleteConfirmModal}
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              isRowDisableLayout={item => !(item as any).user.enabled}
              model={tableModel}
              extraActions={this.getExtraActions()}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              onPageChange={this.handlePageChange}
              canCreateNew={false}
              canEdit={false}
              canDelete={false}
              extraMenu={[
                {
                  className: 'menu-item-download',
                  content: (
                    <Button
                      icon
                      size="medium"
                      onClick={this.initExportTheoreticalTestProcess}
                      className="custom-table-upload-btn black-btn"
                      data-tooltip={t('Export')}
                    >
                      <FontAwesomeIcon className="solid" icon={faFileDownload} size="lg" />
                    </Button>
                  )
                }
              ]}
            />
          </Grid.Row>
        </Grid>

        <Modal
          open={showDeleteConfirmModal}
          size="mini"
          className="have-warnings-popup"
          closeOnEscape={true}
          onClose={this.hideConfirmModal}
        >
          <Modal.Content className="have-warnings-popup__content">
            <p>
              {t(
                `This TNA theoretical test ${selectedItem?.tnaDetails?.title} will be deleted. Are you sure that you want to delete this item?`
              )}
            </p>
          </Modal.Content>
          <div className="have-warnings-popup__buttons-container">
            <Button className="have-warnings-popup__btn" content={t('No')} onClick={this.hideConfirmModal} />
            <Button
              className="have-warnings-popup__btn have-warnings-popup__pending-btn"
              content={t('Yes')}
              onClick={() => this.onDelete(selectedItem?.id)}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(TnaTheoreticalTestList);
