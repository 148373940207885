import * as React from 'react';
import { Modal, Tab } from 'semantic-ui-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import PublicHolidaysTab, { PublicHolidayConf } from './config-tabs/public-holidays-tab';
import CountryPublicHolidaysTab, { CountryPublicHolidayConf } from './config-tabs/country-public-holidays-tab';
import './scheduler-poc-config.style.less';
import WorkingDaysTab from './config-tabs/working-days-tab';
import { CreateWorkingDaysDto } from 'stores/working-days/working-days-store';
import { IdentityService } from 'services/identity-service';
import { container } from 'inversify.config';

export interface SchedulerPoCConfigProps extends WithTranslation {
  onClose?: (data?: CountryPublicHolidayConf | PublicHolidayConf | CreateWorkingDaysDto) => void;
}
const SchedulerPoCConfig: React.FC<SchedulerPoCConfigProps> = ({ t, onClose }) => {
  const identityService: IdentityService = container.get(IdentityService);

  const getPanes = () => {
    let panes = [];
    const activeRole = identityService.activeRole;
    if (activeRole === 'PoC') {
      panes.push({ menuItem: t('Country Public Holidays'), render: () => <CountryPublicHolidaysTab onClose={onClose} /> });
    }
    panes.push({ menuItem: t('Location Public Holidays'), render: () => <PublicHolidaysTab onClose={onClose} /> });
    panes.push({ menuItem: t('Working Days'), render: () => <WorkingDaysTab onClose={onClose} /> });

    return panes;
  };

  return (
    <Modal size="small" className="scheduler-poc-config" open closeOnEscape={true} onClose={() => onClose()}>
      <Modal.Header className="scheduler-poc-config__header">{t('Calendar Configuration')}</Modal.Header>
      <Modal.Content className="scheduler-poc-config__content">
        <Tab className="scheduler-poc-config__tabs" menu={{ secondary: true, pointing: true }} panes={getPanes()} />
      </Modal.Content>
    </Modal>
  );
};

export default withTranslation()(React.memo(SchedulerPoCConfig));
