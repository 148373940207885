import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import TabWithHeader from '../../../shared/events-and-requests/tab-with-header';
import RequestFormHeader from '../request-form-header';
import EventDetailsTabPane from './event-details-tab-pane';
import StudentsContainerComponent from './students-tab-pane-container';
import CommentsTabPane from './comments-tab-pane';
import SupportDetailsTabPane from './support-details-tab-pane-container';
import { RequestFormStore } from 'stores/requests/request-form-store';
import { connect } from 'redux-scaffolding-ts';
import RequestDetailsTabPane from './request-details-tab-pane';
import InstructorsTabPane from './instructors-tab-pane';
import EventTabPane from './event/event-tab-pane';
import { Message } from 'stores/types';

export interface RequestFormTabMenuProps extends WithTranslation {
  requestFormStore?: RequestFormStore;
  readOnly: boolean;
  onEditMode?: boolean;
  extraErrors?: Message[];
  fromEventTab: boolean;
}

@connect(['requestFormStore', RequestFormStore])
class RequestFormTabMenu extends Component<RequestFormTabMenuProps> {
  private buildPanes() {
    const { t, readOnly, requestFormStore, fromEventTab } = this.props;
    const { item } = requestFormStore.state;
    const { hasEvent } = item;
    let panes = [];

    if (item.hasRequestDetails) panes.push({ menuItem: t('Request Details'), render: () => <RequestDetailsTabPane readOnly={readOnly} /> });

    if (item.hasEventDetails) panes.push({ menuItem: t('Event Details'), render: () => <EventDetailsTabPane readOnly={readOnly} /> });

    if (item.hasSupportDetails) panes.push({ menuItem: t('Support Details'), render: () => <SupportDetailsTabPane readOnly={readOnly} /> });

    if (item.hasStudents && item.eventTypeCategory !== 30)
      panes.push({ menuItem: t('Students'), render: () => <StudentsContainerComponent readOnly={readOnly} /> });

    if (item.hasInstructors && item.eventTypeCategory !== 30)
      panes.push({ menuItem: t('Instructors'), render: () => <InstructorsTabPane readOnly={readOnly} /> });

    if (hasEvent && !fromEventTab) panes.push({ menuItem: t('Event'), render: () => <EventTabPane fromRequestTab={true} /> });

    const handleComments = (comments: string) => requestFormStore.change({ comments });

    panes.push({
      menuItem: t('Comments'),
      render: () => <CommentsTabPane readOnly={readOnly} comment={item.comments} onChange={handleComments} />
    });

    return panes;
  }

  render() {
    const { readOnly, extraErrors } = this.props;
    const panes = this.buildPanes();

    return <TabWithHeader header={<RequestFormHeader extraErrors={extraErrors} readOnly={readOnly} />} panes={panes} />;
  }
}

export default withTranslation()(RequestFormTabMenu);
