import { AbstractValidator, ValidationResult } from 'fluent-ts-validator';
import { AsyncAction, ReduxRepository, repository } from 'redux-scaffolding-ts';
import i18n from '../../i18n';
import { DataStore, Query, QueryResult } from '../dataStore';
import { FormStore } from '../formStore';
import { AzureStorageUploadResult, BaseDto, CommandResult, FileInfo, ItemResult, Message, SaSTokenInfoDto } from '../types';
import { container } from 'inversify.config';
import HttpService from 'services/http-service';
import { UserDto } from 'stores/users/users-store';
import { StudentDto } from 'stores/students/student-model';
import { EventTypeCategory, EventTypeDto } from 'stores/configuration/events-workflow/event-types-store';
import { LocationDto } from 'stores/configuration/locations/locations-store';
import { AxiosResponse } from 'axios';
import * as AzureStorageNpm from 'azure-storage';
import { DateTimeService } from 'services/datetime-service';
import { EventDto } from 'stores/events/events-store';

export enum RequestStatus {
  Draft = 10,
  Pending = 20,
  InProgress = 30,
  Accepted = 40,
  Rejected = 50
}

export enum Categories {
  Technical = 10,
  Functional = 20
}

export const getAllCategories = () => {
  let map: { text: string; value: string }[] = [];

  for (let n in Categories) {
    if (typeof Categories[n] === 'number') {
      map.push({ text: n, value: Categories[n] });
    }
  }
  return map;
};

export interface RequestUploaderState {
  isBusy: boolean;
  result: AzureStorageUploadResult;
}

export interface RequestMachinesDto {
  machineModelId: string;
  machineModelName: string;
  machineRelatedClusterId: string;
  machineRelatedClusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  machineUnitRequestMachines: string[];
  plcTypeRequestMachines: string[];
}

export interface CreateRequestMachinesDto {
  machineRelatedClusterId: string;
  machineRelatedClusterName: string;
  equipmentTypeId: string;
  equipmentTypeName: string;
  oemId: string;
  oemName: string;
  machineModelId: string;
  machineModelName: string;
  plcTypeRequestMachines: string[];
  machineUnitRequestMachines: string[];
}

export interface RequestDto extends BaseDto {
  id: string;
  requestId: string;
  friendlyId: string;
  title: string;
  requestOwnerId: string;
  requestOwner: UserDto;
  status: RequestStatus;
  statusDescription: string;
  isCanceled: boolean;
  eventTypeId: string;
  eventTypeName: string;
  eventType: EventTypeDto;
  requestLocation: LocationDto;
  eventLocation: LocationDto;
  startDate: string;
  endDate: string;
  desiredEventDuration: string;
  comments: string;
  requestingLocationId: string;
  requestingLocationName: string;
  requestingLocationCountryName: string;
  priorityId: string;
  priorityName: string;
  eventLocationId: string;
  eventLocationName: string;
  eventLocationCountryName: string;
  category: Categories;
  roleId: string;
  roleName: string;
  isMachineRelated: boolean;
  requestReasonId: string;
  requestReasonName: string;
  requestReasonComments: string;
  patternId: string;
  patternName: string;
  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
  requestMachines: RequestMachinesDto[];
  machineModelName: string;
  plcTypeName: string;
  nmrClusterName: string;
  nmrFunctionalAreaName: string;
  nmrFunctionalSubAreaName: string;
  nmrTrainingNameName: string;
  trainingLevelId: string;
  trainingLevelName: string;
  customizationDataId: string;
  customizationDataName: string;
  deliveryMethodId: string;
  deliveryMethodName: string;
  languageId: string;
  languageName: string;
  languageCode: string;
  studentsNumber: number;
  students: StudentDto[];
  instructorLocationId: string;
  instructorLocationName: string;
  instructorLocationCountryName: string;
  instructorId: string;
  instructorName: string;
  instructor: UserDto;
  eventCreatedId: string;
  supportDetails: SupportDetails;
  extendedStatusValidation: ExtendedStatusValidation;
  submissionDate: string;
  eventCreateFriendlyId: string;
  eventItemId: string;
  eventItem: EventDto;
}

export interface ExtendedStatusValidation {
  validatedByGlobalEngineers: string[];
  validatedByGlobalManufacturers: string[];
  validatedByPlanners: string[];
  validatedByRegionalManufacturersVP: string[];
}

export interface SupportDetails {
  totalCostLocationA: number;
  totalCostLocationB: number;
  totalCostLocationC: number;
  totalCostLocationD: number;
  supportPositions: SupportPositions[];
  requestPreApprovedDuringASP: boolean;
  newSupportPositions: NewSupportPositions[];
  totalRequestedWorkingManHours: number;
  totalRequestedManDays: number;
  totalRequestedManMonths: number;
  totalTheoreticalCost: number;
  isNewSupportPositionModel: boolean;
}
export interface SupportPositions {
  positionCode: string;
  positionCodeName: string;
  comment: string;
  requestedHC: number;
  duration: string;
  locationA: SupportLocationItem;
  locationB: SupportLocationItem;
  locationC: SupportLocationItem;
  locationD: SupportLocationItem;
  startDate: string;
  endDate: string;
}

export interface NewSupportPositions {
  requestedHC: number;
  supportPositionRoleId: string;
  supportPositionRoleName: string;
  patternId: string;
  patternName: string;
  machineModels: RequestMachinesDto[];
  startDate: string;
  endDate: string;
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
}

export interface SupportLocationItem {
  locationId: string;
  location: LocationDto;
  estimatedCost: number;
}

export interface CreateSupportDetailsDto {
  totalCostLocationA: number;
  totalCostLocationB: number;
  totalCostLocationC: number;
  totalCostLocationD: number;
  supportPositions: CreateSupportPositionDto[];
  requestPreApprovedDuringASP: boolean;
  newSupportPositions: CreateNewSupportPositionDto[];
  totalRequestedWorkingManHours: number;
  totalRequestedManDays: number;
  totalRequestedManMonths: number;
  totalTheoreticalCost: number;
  isNewSupportPositionModel: boolean;
}

export interface CreateSupportPositionDto {
  positionCode: string;
  comment: string;
  requestedHC: number;
  duration: string;
  locationA: CreateSupplyingLocationDto;
  locationB: CreateSupplyingLocationDto;
  locationC: CreateSupplyingLocationDto;
  locationD: CreateSupplyingLocationDto;
  startDate: string;
  endDate: string;
}

export interface CreateSupplyingLocationDto {
  locationId: string;
  estimatedCost: number;
}

export interface CreateNewSupportPositionDto {
  requestedHC: number;
  supportPositionRoleId: string;
  patternId: string;
  patternName: string;
  machineModels: RequestMachinesDto[];
  startDate: string;
  endDate: string;
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
}

export interface ChangeSupportDetailsDto {
  totalCostLocationA: number;
  totalCostLocationB: number;
  totalCostLocationC: number;
  totalCostLocationD: number;
  supportPositions: ChangeSupportPositionDto[];
  requestPreApprovedDuringASP: boolean;
  newSupportPositions: ChangeNewSupportPositionDto[];
  totalRequestedWorkingManHours: number;
  totalRequestedManDays: number;
  totalRequestedManMonths: number;
  totalTheoreticalCost: number;
  isNewSupportPositionModel: boolean;
}

export interface ChangeSupportPositionDto {
  positionCode: string;
  comment: string;
  requestedHC: number;
  duration: string;
  locationA: ChangeSupplyingLocationDto;
  locationB: ChangeSupplyingLocationDto;
  locationC: ChangeSupplyingLocationDto;
  locationD: ChangeSupplyingLocationDto;
  startDate: string;
  endDate: string;
}

export interface ChangeSupplyingLocationDto {
  locationId: string;
  estimatedCost: number;
}

export interface CreateSupplyingLocationDto {
  locationId: string;
  estimatedCost: number;
}

export interface ChangeNewSupportPositionDto {
  requestedHC: number;
  supportPositionRoleId: string;
  patternId: string;
  patternName: string;
  machineModels: RequestMachinesDto[];
  startDate: string;
  endDate: string;
  requestedManDays: number;
  requestedWorkingManHours: number;
  theoreticalCost: number;
}

export interface CreateRequestDto {
  requestId: string;
  title: string;
  requestOwnerId: string;
  status: RequestStatus;
  isCanceled: boolean;
  eventTypeId: string;
  eventTypeName: string;
  eventTypeRequestDetails: string[];
  startDate: Date;
  endDate: Date;
  desiredEventDuration: string;
  comments: string;
  requestingLocationId: string;
  requestingLocationName: string;
  requestingCountryName: string;
  priorityId: string;
  priorityName: string;
  eventLocationId: string;
  eventLocationName: string;
  eventLocationCountryName: string;
  category: Categories;
  roleId: string;
  roleName: string;
  isMachineRelated: boolean;
  requestReasonId: string;
  requestReasonComments: string;
  //requestPreApprovedDuringASPCheck: boolean;
  patternId: string;
  patternName: string;
  machineModelName: string;
  plcTypeName: string;
  nmrClusterId: string;
  nmrClusterName: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalAreaName: string;
  nmrFunctionalSubAreaId: string;
  nmrFunctionalSubAreaName: string;
  nmrTrainingNameId: string;
  nmrTrainingNameName: string;
  trainingLevelId: string;
  trainingLevelName: string;
  customizationDataId: string;
  customizationDataName: string;
  deliveryMethodId: string;
  deliveryMethodName: string;
  languageId: string;
  languageName: string;
  languageCode: string;
  studentsNumber: number;
  students: string[];
  instructorLocationId: string;
  instructorLocationName: string;
  instructorLocationCountryName: string;
  instructorId: string;
  instructorName: string;
  eventsColor: string;
  isEventDetails: boolean;
  isRequestDetails: boolean;
  isInstructorYes: boolean;
  requestMachines: CreateRequestMachinesDto[];
  supportDetails: CreateSupportDetailsDto;
}

export interface ChangeRequestDto {
  id: string;
  title: string;
  requestOwnerId: string;
  comments: string;
  requestingLocationId: string;
  priorityId: string;
  eventLocationId: string;
  eventTypeCategory: EventTypeCategory;
  category: Categories;
  roleId: string;
  nmrClusterId: string;
  nmrFunctionalAreaId: string;
  nmrFunctionalSubAreaId: string;
  nmrTrainingNameId: string;
  requestReasonId: string;
  requestReasonComments: string;
  //requestPreApprovedDuringASPCheck: boolean;
  customizationDataId: string;
  languageId: string;
  deliveryMethodId: string;
  startDate: string;
  endDate: string;
  patternId: string;
  studentsNumber: number;
  students: string[];
  instructorLocationId: string;
  instructorId: string;
  trainingLevelId: string;
  isMachineRelated: boolean;
  requestMachines: RequestMachinesDto[];
  desiredEventDuration: string;
  supportDetails: ChangeSupportDetailsDto;
}

interface ChangeRequestStatusDto {
  requestId: string;
  requestStatus: RequestStatus;
  rejectReason: string;
  rejectionReasonId?: string;
  rejectedById?: string;
}

export interface CancelRequestItemDto {
  id: string;
  rejectReason: string;
  rejectedById: string;
  rejectionReasonId: string;
}
export class CreateRequestValidator extends AbstractValidator<CreateRequestDto> {
  constructor() {
    super();

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));
  }
}

export class ChangeRequestValidator extends AbstractValidator<ChangeRequestDto> {
  constructor() {
    super();

    this.validateIfString(o => o.id)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Id is required'));

    this.validateIfString(o => o.title)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Title is required'));

    this.validateIf(t => t.desiredEventDuration)
      .isNotNull()
      .fulfills(x => parseFloat(x) > 0)
      .withFailureMessage(i18n.t('Desired event duration must be greater than 0'));

    this.validateIf(x => x)
      .fulfills(x => x.studentsNumber >= x.students.length)
      .when(x => x != null && x.studentsNumber != null && x.students != null)
      .withFailureMessage(i18n.t('Students Assigned must be greater or equal than the number of student added'));

    this.validateIfString(o => o.instructorId)
      .isNotEmpty()
      .when(t => t.instructorLocationId != null)
      .withFailureMessage(i18n.t("Instructor's Name is required"));
  }
}

export class RejectRequestValidator extends AbstractValidator<ChangeRequestStatusDto> {
  constructor() {
    super();

    this.validateIfString(x => x.rejectReason)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Invalid Reject reason text'));

    this.validateIfString(x => x.rejectionReasonId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Is required to select Reject Reason'));

    this.validateIfString(x => x.rejectedById)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Is required to select Rejected By'));
  }
}

export class CancelRequestValidator extends AbstractValidator<CancelRequestItemDto> {
  constructor() {
    super();

    this.validateIfString(x => x.rejectReason)
      .isNotEmpty()
      .withFailureMessage(i18n.t('Invalid Reject reason text'));

    this.validateIfString(x => x.rejectionReasonId)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Is required to select Reject Reason'));

    this.validateIfString(x => x.rejectedById)
      .isNotEmpty()
      .isUuid('4')
      .withFailureMessage(i18n.t('Is required to select Rejected By'));
  }
}

@repository('@@REQUESTS', 'requests.summary')
export class RequestsStore extends DataStore<RequestDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-request';
  retrievePath = 'get-requests';
  updatePath = 'update-request';
  deletePath = 'delete-request';
  retrieveOnePath = 'get-request';
  retriveRoleIsCreatorPath = 'get-if-request-creator-role';
  retrieveListPath = 'get-requests-list';
  clonePath = 'clone-request';
  REQUEST_CLONE = 'REQUEST_CLONE';

  protected validate(item: RequestDto) {
    return new ValidationResult();
  }

  constructor() {
    super('REQUEST', {
      isBusy: false,
      items: [],
      count: 0,
      result: undefined,
      discard: item => {}
    });
  }

  public async getById(id: string): Promise<RequestDto> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<RequestDto>(`${this.baseUrl}/${this.retrieveOnePath}/${id}`);
    return result.data;
  }

  public async cloneById(id: string): Promise<CommandResult<RequestDto>> {
    const httpService = container.get(HttpService);
    const result = await this.dispatchAsync(
      this.REQUEST_CLONE,
      httpService.post<any, CommandResult<RequestDto>>(`${this.baseUrl}/${this.clonePath}/${id}`, this.state.item)
    );
    return result.data;
  }

  public async getIfRoleIsCreator(role: string): Promise<boolean> {
    const httpService = container.get(HttpService);
    const result = await httpService.get<boolean>(`${this.baseUrl}/${this.retriveRoleIsCreatorPath}/${role}`);
    return result.data;
  }

  public async getAllRequestListAsync(query: Query, data?: any): Promise<QueryResult<RequestDto>> {
    let httpService = container.get<HttpService>(HttpService);
    const { path, body } = DataStore.getRequestParts(query);

    if (body != null) {
      data = data || {};
      data = { ...data, ...body };
    }

    const result = await this.dispatchAsync(
      this.ENTITY_LIST_UPDATE,

      httpService.get<QueryResult<RequestDto>>(`${this.baseUrl}/${this.retrieveListPath}?${path}`, data)
    );
    return result.data;
  }
}

@repository('@@REQUESTS', 'requests.new')
export class NewRequestStore extends FormStore<CreateRequestDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-request';
  retrievePath = 'get-requests';
  updatePath = 'update-request';

  protected validate(item: CreateRequestDto) {
    return new CreateRequestValidator().validate(item);
  }

  constructor() {
    super('NEW_REQUEST', {
      isBusy: false,
      status: 'New',
      item: undefined,
      result: undefined
    });
  }
}
@repository('@@REQUESTS', 'requests.change')
export class ChangeRequestStore extends FormStore<ChangeRequestDto> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  createPath = 'new-request';
  retrievePath = 'get-requests';
  updatePath = 'update-request';

  protected validate(item: ChangeRequestDto) {
    return new ChangeRequestValidator().validate(item);
  }

  public async deleteById(id: string, rejectReason: string, rejectionReasonId: string, rejectedById: string) {
    const httpService = container.get(HttpService);
    const deletePath = 'cancel-request';
    const cancelDto = { id, rejectReason, rejectionReasonId, rejectedById };
    const validation = new CancelRequestValidator().validate(cancelDto);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return {
        isSuccess: false,
        messages: validation.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    }

    const result = await this.dispatchAsync(deletePath, httpService.delete(`${this.baseUrl}/${deletePath}/${id}`, cancelDto));
    return result.data;
  }

  public async rejectRequest(requestId: string, rejectReason: string, rejectionReasonId: string, rejectedById: string) {
    const httpService = container.get(HttpService);
    const changeRequestStatusPath = 'change-status-request';

    const changeDTO = { requestId, requestStatus: RequestStatus.Rejected, rejectReason, rejectionReasonId, rejectedById };
    const validation = new RejectRequestValidator().validate(changeDTO);
    if (validation.isInvalid()) {
      this.dispatch(this.ENTITY_VALIDATED, validation);
      return {
        isSuccess: false,
        messages: validation.getFailures().map(o => ({ propertyName: o.propertyName, body: o.message, level: o.severity } as Message))
      } as any;
    }
    const result = await this.dispatchAsync(
      this.ENTITY_CHANGED,
      httpService.put<ChangeRequestStatusDto, RequestDto>(`${this.baseUrl}/${changeRequestStatusPath}`, changeDTO)
    );
    return result.data;
  }

  public async changeStatus(
    requestId: string,
    requestStatus: RequestStatus,
    rejectReason: string = null,
    rejectedById: string = null,
    rejectionReasonId: string = null
  ): Promise<RequestDto> {
    const httpService = container.get(HttpService);
    const changeRequestStatusPath = 'change-status-request';

    const changeRequestStatusDto: ChangeRequestStatusDto = { requestId, requestStatus, rejectReason, rejectedById, rejectionReasonId };

    const result: AxiosResponse<ItemResult<RequestDto>> = await this.dispatchAsync(
      this.ENTITY_SAVE,
      httpService.put<ChangeRequestStatusDto, ItemResult<RequestDto>>(`${this.baseUrl}/${changeRequestStatusPath}`, changeRequestStatusDto)
    );
    const finalResult = result.data.item;

    return finalResult;
  }

  public mergeRequests = async (parentRequestId: string, requestIds: string[]): Promise<any> => {
    let httpService = container.get<HttpService>(HttpService);
    const result = await this.dispatchAsync(
      this.ENTITY_CHANGED,
      httpService.post<string[], any>(`events/v1/merge-request/${parentRequestId}`, requestIds)
    );
    return result.data;
  };

  constructor() {
    super('CHANGE_REQUEST', {
      isBusy: false,
      status: 'Modified',
      item: undefined,
      result: undefined
    });
  }
}

@repository('@@REQUESTS', 'requests.upload')
export class UploadRequestStore extends ReduxRepository<RequestUploaderState> {
  //baseUrl = 'http://localhost:7071/api/v1';
  baseUrl = 'events/v1';
  getSaSTokenUrl = 'get-requests-blob-sas-url';
  downloadTemplateUrl = 'get-requests-import-template';

  public SAS_TOKEN_OBTAINED = 'SAS_TOKEN_OBTAINED';
  public FILE_UPLOADED = 'FILE_UPLOADED';
  public TEMPLATE_DOWNLOADED = 'TEMPLATE_DOWNLOADED';

  constructor() {
    super({
      isBusy: false,
      result: undefined
    });

    this.uploadFile.bind(this);
    this.onSaSTokenObtained.bind(this);
    this.onFileUploaded.bind(this);
    this.onTemplateDownloaded.bind(this);

    this.addReducer(this.SAS_TOKEN_OBTAINED, this.onSaSTokenObtained, 'AsyncAction');
    this.addReducer(this.FILE_UPLOADED, this.onFileUploaded, 'Simple');
    this.addReducer(this.TEMPLATE_DOWNLOADED, this.onTemplateDownloaded, 'AsyncAction');
  }

  public async uploadFile(fileInfo: FileInfo) {
    const { fileName, fileSize } = fileInfo;
    const params = { fileName, fileSize };
    const url = `${this.baseUrl}/${this.getSaSTokenUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.SAS_TOKEN_OBTAINED, httpService.get<ItemResult<SaSTokenInfoDto>>(url, params), fileInfo);
  }

  private onSaSTokenObtained = (): AsyncAction<AxiosResponse<ItemResult<SaSTokenInfoDto>>, RequestUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: (result: AxiosResponse<ItemResult<SaSTokenInfoDto>>, fileInfo: FileInfo) => {
        let data = result.data.item;
        const blobService = AzureStorage.Blob.createBlobServiceWithSas(data.host, data.saSToken).withFilter(
          new AzureStorageNpm.ExponentialRetryPolicyFilter(5)
        );
        const customBlockSize = fileInfo.fileSize > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
        blobService.singleBlobPutThresholdInBytes = customBlockSize;

        blobService.createBlockBlobFromBrowserFile(
          data.container,
          data.filePath,
          fileInfo.content,
          {
            blockSize: customBlockSize,
            metadata: {
              originalName: encodeURIComponent(fileInfo.fileName),
              userId: fileInfo.userId,
              userName: fileInfo.userName,
              timestamp: DateTimeService.toString(DateTimeService.now())
            }
          },
          (error: Error, _: AzureStorageNpm.BlobService.BlobResult, response: AzureStorageNpm.ServiceResponse) => {
            const res = { error, response };
            this.dispatch(this.FILE_UPLOADED, res);
          }
        );
        return { ...this.state };
      },
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };

  protected onFileUploaded = (result: AzureStorageUploadResult): RequestUploaderState => {
    return { ...this.state, isBusy: false, result };
  };

  public async downloadTemplate() {
    const url = `${this.baseUrl}/${this.downloadTemplateUrl}`;

    const httpService = container.get<HttpService>(HttpService);
    return this.dispatchAsync(this.TEMPLATE_DOWNLOADED, httpService.download(url, 'Requests.xlsx'));
  }

  private onTemplateDownloaded = (): AsyncAction<AxiosResponse<any>, RequestUploaderState> => {
    return {
      onStart: () => ({ ...this.state, isBusy: true, result: undefined }),
      onSuccess: () => ({ ...this.state, isBusy: false, result: undefined }),
      onError: error => ({
        ...this.state,
        isBusy: false,
        result:
          error && error.response && error.response.data && error.response.data.messages
            ? {
                error: { message: error.response.data.messages.map(x => x.body).join('.') } as Error,
                response: undefined as AzureStorageNpm.ServiceResponse
              }
            : { error: undefined as Error, response: { isSuccessful: false } as AzureStorageNpm.ServiceResponse }
      })
    };
  };
}
