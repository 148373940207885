import React, { Fragment } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'redux-scaffolding-ts';
import { Message, Icon, Form, Button, Table, Checkbox, Popup, Dimmer, Loader, Grid } from 'semantic-ui-react';

import { OrderDefinition, Query } from '../../../../stores/dataStore';
import { UserImportDto, UserImportStore } from '../../../../stores/user-import/user-import-store';
import { nameof } from '../../../../utils/object';
import { TableModel, TableView } from '../../../../widgets/collections/table';
import { TextBoxFilter } from '../../../../widgets/collections/table-filters/textbox-filter';
import { OptionsFilter } from 'widgets/collections/table-filters/option-filter';
import { DatesFilter } from 'widgets/collections/table-filters/dates-filter';
import UserImportDeletePopup from './buttons/user-import-delete/user-import-delete-popup';
import ChooseUserImportsView from 'widgets/form/choose-userimports-form';
import NewPositionCodeView from '../../configuration/profiles/positionCode/new-positionCode';
import { MatchRowDto } from 'stores/user-import/match-rows-store';
import { CreateUserDto, UserDto, ChangeUserStore, CreateRoleInLocationDto, ChangeUserDto } from 'stores/users/users-store';
import NewUserView from '../../users/new-user';
import ChangeUserView from '../../users/edit-user';
import { DateTimeService } from 'services/datetime-service';
import { BaseCommandResult } from 'stores/types';

export interface UserImportViewProps extends WithTranslation {
  userimportStore?: UserImportStore;
  changeUserStore?: ChangeUserStore;
}

export interface UserImportViewState {
  activeFilters: string[];
  selectedItem: any;
  selectedRow: MatchRowDto;
  showDeleteConfirm: boolean;
  query: Query;
  filteredByMatched: boolean;
  isOpenedUsersModal: boolean;
  isOpenedNewPositionCodeModal: boolean;
  newUserShown: boolean;
  editUserShown: boolean;
  newUserFromImport: CreateUserDto;
  changeUserFromImport: CreateUserDto;
  userImportChangeId: string;
  userImportChangeReason: string;
  userImportSAPId: string;
  pruning: boolean;
  pruneResponse: BaseCommandResult;
  updateUserResponse: BaseCommandResult;
  loading: boolean;
  someFilterOpened: boolean;
  selectedLocationNotMappedUser: UserDto;
}

@connect(['userimportStore', UserImportStore], ['changeUserStore', ChangeUserStore])
class UserImportView extends React.Component<UserImportViewProps, UserImportViewState> {
  handleOnActivateFilter = (visible: boolean) => {
    this.setState({ someFilterOpened: visible });
  };

  handleOnEnterKeydown = (item: UserImportDto) => {
    if (
      !item.reason ||
      item.reason === 'User not found' ||
      item.reason === 'Duplicate User Import' ||
      item.reason === 'Description Code not found' ||
      item.reason === 'Position Code not found'
    ) {
      this.onNewUser(item);
    } else if (item.reason === 'New Personnel Area found' || item.reason === 'Employee/s suggested to update') {
      this.onChangeUser(item);
    }
  };

  handleOnRowDoubleClick = (item: UserImportDto) => {
    this.handleOnEnterKeydown(item);
  };

  constructor(props: UserImportViewProps) {
    super(props);

    this.changeUserStore.state.result = null;

    this.state = {
      query: {
        searchQuery: '',
        orderBy: [
          {
            direction: 'Descending',
            field: nameof<UserImportDto>('createdOn'),
            useProfile: false
          },
          {
            direction: 'Ascending',
            field: nameof<UserImportDto>('userId'),
            useProfile: false
          }
        ],
        skip: 0,
        take: 10,
        parameters: { skipEmptyReasons: 'True' }
      },
      selectedItem: null,
      selectedRow: null,
      showDeleteConfirm: false,
      activeFilters: [],
      filteredByMatched: false,
      isOpenedUsersModal: false,
      isOpenedNewPositionCodeModal: false,
      newUserShown: false,
      editUserShown: false,
      userImportSAPId: '',
      newUserFromImport: {
        firstName: '',
        lastName: '',
        enabled: true,
        employeeId: '',
        positionCodeId: '',
        positionCode: null,
        origin: '',
        region: '',
        personnelAreaId: '',
        personnelArea: '',
        sfPosition: '',
        locationId: '',
        location: '',
        password: '',
        portrait: '',
        roles: [],
        userName: '',
        email: '',
        lineManager: '',
        acronym: '',
        languages: [],
        trainingLanguages: [],
        hireDate: '',
        isGlobalPoc: false
      },
      changeUserFromImport: {
        firstName: '',
        lastName: '',
        enabled: true,
        employeeId: '',
        positionCodeId: '',
        positionCode: null,
        origin: '',
        region: '',
        personnelAreaId: '',
        personnelArea: '',
        sfPosition: '',
        locationId: '',
        location: '',
        password: '',
        portrait: '',
        roles: [],
        userName: '',
        email: '',
        lineManager: '',
        acronym: '',
        languages: [],
        trainingLanguages: [],
        hireDate: '',
        isGlobalPoc: false
      },
      userImportChangeId: '',
      userImportChangeReason: '',
      pruning: false,
      pruneResponse: null,
      updateUserResponse: null,
      loading: false,
      someFilterOpened: false,
      selectedLocationNotMappedUser: {
        id: '',
        firstName: '',
        lastName: '',
        userName: '',
        email: '',
        roles: [],
        locationId: '',
        location: null,
        lineManager: '',
        portrait: '',
        enabled: true,
        employeeId: '',
        positionCodeId: '',
        positionCode: null,
        origin: '',
        region: '',
        personnelAreaId: '',
        personnelArea: '',
        sfPosition: '',
        updateFlag: true,
        hireDate: '',
        leavingDate: '',
        acronym: '',
        languages: [],
        trainingLanguages: [],
        hasProfileAssigned: false,
        pillarId: '',
        pillar: null,
        instructorRoleId: '',
        instructorRole: null,
        isAssessor: false,
        deletedOn: '',
        deleted: false,
        rowVersion: '',
        isPowerInstructor: false,
        friendlyId: '',
        userPhoto: null,
        isGlobalPoc: false,
        createdOn: '',
        modifiedOn: '',
        modifiedByUserId: '',
        modifiedByUser: ''
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  private load = () => {
    this.changeUserStore.reset();
    this.setState({
      pruning: false,
      pruneResponse: null,
      updateUserResponse: null
    });
    this.props.userimportStore.getAllAsync(this.state.query);
  };

  private get changeUserStore() {
    return this.props.changeUserStore;
  }

  private handleOrderBy = (orderBy: OrderDefinition[]) => {
    this.setState(
      {
        query: Object.assign(this.state.query, {
          orderBy: [...orderBy]
        })
      },
      this.load
    );
  };

  private handlePageChange = (skip: number, take: number) => {
    this.setState({ query: Object.assign(this.state.query, { skip, take }) }, this.load);
  };

  private handleFilterChange = (filters: { id: string; filter: any }[]) => {
    const filter = filters.map(f => f.filter);
    const activeFilters = filters.map(f => f.id);

    const query = Object.assign(this.state.query, { filter, skip: 0 });
    this.setState({ query, activeFilters }, this.load);
  };

  private handleRowChange = (row: MatchRowDto) => {
    this.setState({
      selectedRow: this.state.selectedRow?.id === row.id ? null : row
    });
  };

  private onShowDelete = () => {
    this.setState({
      showDeleteConfirm: true
    });
  };

  private onDelete = async () => {
    this.closeWindowModal();
    await this.props.userimportStore.deleteAsync(this.state.selectedItem.id);
    this.load();
    return { isSuccess: true, items: [], messages: [] };
  };

  private closeWindowModal = () => {
    this.setState({ showDeleteConfirm: false });
  };

  private onFilterMatched = () => {
    let searchQuery: string = '';

    if (this.state.filteredByMatched === false) {
      searchQuery = 'MatchRows/any(s:s/Id ne null)';
      this.setState({ filteredByMatched: true });
    } else this.setState({ filteredByMatched: false });

    const activeFilters = [searchQuery];
    const filter = searchQuery;
    const query = Object.assign(this.state.query, { filter, skip: 0 });

    this.setState({ query, activeFilters }, this.load);
  };

  private onOpenUsersSelectionModal = () => {
    this.setState({
      isOpenedUsersModal: true
    });
  };

  private onOpenNewPositionCodeModal = () => {
    this.setState({
      isOpenedNewPositionCodeModal: true
    });
  };

  private onOverwriteData = async () => {
    if (!this.state.selectedRow || this.state.selectedRow.userImportId !== this.state.selectedItem.id) {
      return;
    }
    this.setState({ loading: true });
    const matchedRow = this.state.selectedRow;

    let ok = false;

    const userImport = this.state.selectedItem as UserImportDto;
    try {
      const u = ((await this.changeUserStore.getById(matchedRow.idPUserId)) as unknown) as UserDto;
      if (u != null) {
        this.props.changeUserStore.reset();
        this.props.changeUserStore.change({
          id: u.id,
          email: userImport.email || u.email,
          employeeId: userImport.userId || u.employeeId,
          enabled: !!userImport.active,
          firstName: userImport.name || u.firstName,
          lastName: userImport.surname || u.lastName,
          lineManager: userImport.lineManagerName || u.lineManager,
          personnelAreaId: userImport.personnelAreaId || u.personnelAreaId,
          personnelArea: userImport.personnelArea || u.personnelArea,
          positionCodeId: userImport.positionCodeId || u.positionCodeId,
          positionCode: userImport.positionCode || u.positionCode?.name,
          sfPosition: userImport.positionSF || u.sfPosition,
          region: userImport.region || u.region,
          origin: userImport.country || u.origin,
          hireDate: userImport.hireDate || u.hireDate ? DateTimeService.toString(userImport.hireDate || u.hireDate) : u.hireDate,
          locationId: userImport.locationCode || u.locationId,
          userName: userImport.userName || u.userName,
          languages: u.languages,
          trainingLanguages: u.trainingLanguages,
          location: userImport.location || u.location?.location,
          roles: (u.roles || []).map(x => ({ roleName: x.role?.name, locationId: x.location?.id, regionId: x.region?.id })),
          acronym: u.acronym,
          hasProfileAssigned: u.hasProfileAssigned,
          portrait: u.portrait,
          updateFlag: u.updateFlag
        });

        const r = await this.props.changeUserStore.updateUserWithFlags(true, true);
        if (!!r?.isSuccess) {
          await this.props.userimportStore.deleteAsync(userImport.id);
          ok = true;
        }
      } else {
        this.props.changeUserStore.state.result = {
          isSuccess: false,
          messages: [{ level: 'Error', body: 'User not found' }],
          items: []
        };
      }
    } catch {}

    this.setState({ loading: false }, ok ? this.load : undefined);
  };

  private onNewPositionCodeClosed = () => {
    this.setState({ isOpenedNewPositionCodeModal: false }, this.load);
  };

  private onNewUser = (item: UserImportDto) => {
    let create: CreateUserDto = {
      firstName: item.name || '',
      lastName: item.surname || '',
      enabled: item.active,
      employeeId: item.userId || '',
      positionCodeId: item.positionCodeId || '',
      positionCode: item.positionCode || '',
      origin: item.country || '',
      region: item.region || '',
      personnelAreaId: item.personnelAreaId || '',
      personnelArea: item.personnelArea || '',
      sfPosition: item.positionSF || '',
      locationId: item.locationCode || '',
      location: item.location || '',
      password: '',
      portrait: '',
      roles: item.locationCode ? [{ roleName: 'Employee', locationId: item.locationCode, regionId: undefined }] : [],
      userName: item.userName || '',
      email: item.email || '',
      lineManager: item.lineManagerName || '',
      acronym: '',
      languages: [],
      trainingLanguages: [],
      hireDate: item.hireDate ? DateTimeService.toString(item.hireDate) : '',
      isGlobalPoc: item.isGlobalPoc
    };
    this.setState({ newUserFromImport: create, newUserShown: true, userImportSAPId: item.userId || '' });
  };

  private onNewUserClosed = (saved: boolean) => {
    this.setState({ newUserShown: false });
    if (saved === true) {
      this.props.userimportStore
        .deleteUserImportBySapIdAsync(this.state.userImportSAPId)
        .then(() => {
          this.load();
        })
        .catch(_ => {
          // do something
        });
    }
  };

  private onEditUserClosed = (saved: boolean) => {
    this.setState({ editUserShown: false });
    if (saved === true) {
      this.props.userimportStore
        .deleteAsync(this.state.userImportChangeId)
        .then(() => {
          this.load();
        })
        .catch(_ => {
          // do something
        });
    }
  };

  private onChangeUser = (item: UserImportDto) => {
    let change: CreateUserDto = {
      firstName: item.name || '',
      lastName: item.surname || '',
      enabled: !!item.active,
      employeeId: item.userId || '',
      positionCodeId: item.positionCodeId || '',
      positionCode: item.positionCode || '',
      origin: item.country || '',
      region: item.region || '',
      personnelAreaId: item.personnelAreaId || '',
      personnelArea: item.personnelArea || '',
      sfPosition: item.positionSF || '',
      locationId: item.locationCode || '',
      location: item.location || '',
      password: '',
      portrait: '',
      roles: [],
      userName: item.userName || '',
      email: item.email || '',
      lineManager: item.lineManagerName || '',
      acronym: '',
      languages: [],
      trainingLanguages: [],
      hireDate: DateTimeService.toString(item.hireDate) || '',
      isGlobalPoc: !!item.isGlobalPoc
    };
    this.setState({ changeUserFromImport: change, userImportChangeId: item.id, userImportChangeReason: item.reason });
    this.onOpenUsersSelectionModal();
  };

  private handleOnChangeUser = async (user: UserDto) => {
    const { userImportChangeReason } = this.state;
    this.changeUserStore.state.result = null;
    const dataFromImport = this.state.changeUserFromImport;
    const userDto: ChangeUserDto = {
      id: user.id,
      email: dataFromImport.email || user.email,
      employeeId: dataFromImport.employeeId,
      enabled: dataFromImport.enabled,
      firstName: dataFromImport.firstName || user.firstName,
      lineManager: dataFromImport.lineManager || user.lineManager,
      personnelAreaId: dataFromImport.personnelAreaId || user.personnelAreaId,
      personnelArea: dataFromImport.personnelArea || user.personnelArea,
      positionCodeId: dataFromImport.positionCodeId || user.positionCodeId,
      positionCode: dataFromImport.positionCode || user.positionCode?.name,
      sfPosition: dataFromImport.sfPosition || user.sfPosition,
      updateFlag: user.updateFlag,
      lastName: dataFromImport.lastName || user.lastName,
      region: dataFromImport.region || user.region,
      portrait: dataFromImport.portrait || user.portrait,
      languages: dataFromImport.languages || user.languages,
      trainingLanguages: dataFromImport.trainingLanguages || user.trainingLanguages,
      locationId: dataFromImport.locationId || user.locationId,
      location: dataFromImport.location || user.location?.location,
      userName: dataFromImport.userName || user.userName,
      origin: dataFromImport.origin || user.origin,
      hireDate:
        dataFromImport.hireDate || user.hireDate ? DateTimeService.toString(dataFromImport.hireDate || user.hireDate) : user.hireDate,
      leavingDate: !dataFromImport.enabled ? DateTimeService.toString(user.leavingDate || DateTimeService.now()) : null,
      roles: user.roles.map(x => {
        let role: CreateRoleInLocationDto = {
          locationId: x.location ? x.location.id : null,
          roleName: x.role.name,
          regionId: null
        };
        return role;
      }),
      hasProfileAssigned: user.hasProfileAssigned,
      acronym: dataFromImport.acronym || user.acronym,
      instructorRoleId: user.instructorRoleId,
      isGlobalPoc: user.isGlobalPoc
    };

    if (userImportChangeReason === 'Location not mapped') {
      this.setState({ editUserShown: true, selectedLocationNotMappedUser: user });
    } else {
      this.changeUserStore.reset();
      this.changeUserStore.change(userDto);
      this.setState({ loading: true });
      let ok = false;
      try {
        const r = await this.changeUserStore.updateUserWithFlags(true, true);

        if (!!r?.isSuccess) {
          this.setState({
            updateUserResponse: r
          });
          await this.props.userimportStore.deleteAsync(this.state.userImportChangeId);
          ok = true;
        }
      } catch (error) {
        console.error(error);
      }
      this.setState({ loading: false }, ok ? this.load : undefined);
    }
  };

  private setActionsMenu = () => {
    const { t } = this.props;

    let buttonMenuOptions = [
      {
        content: (
          <>
            {t('Overwrite Data')}
            <Icon name="pencil alternate" />
            &nbsp;
          </>
        ),
        disabled: (item: UserImportDto) => !this.state.selectedRow || this.state.selectedRow.userImportId !== item.id,
        onClick: (item: UserImportDto) => {
          if (!this.state.selectedRow || this.state.selectedRow.userImportId !== item.id) return;
          this.setState({ selectedItem: item }, () => this.onOverwriteData());
        },
        isVisible: (item: UserImportDto) => item.reason === 'Employee/s suggested to update'
      },
      {
        content: (
          <>
            {t('New User')}
            <Icon name="user plus" />
            &nbsp;
          </>
        ),
        onClick: (item: UserImportDto) => {
          this.onNewUser(item);
        },
        isVisible: (item: UserImportDto) =>
          !item.reason ||
          item.reason === 'User not found' ||
          item.reason === 'Duplicate User Import' ||
          item.reason === 'Description Code not found' ||
          item.reason === 'Position Code not found'
      },
      {
        content: (
          <>
            {t('Manual Search')}
            <Icon name="search" />
            &nbsp;
          </>
        ),
        onClick: (item: UserImportDto) => {
          this.onChangeUser(item);
        },
        isVisible: (item: UserImportDto) =>
          item.reason === 'New Personnel Area found' ||
          item.reason === 'Employee/s suggested to update' ||
          item.reason === 'Position Code not found' ||
          item.reason === 'Description Code not found' ||
          item.reason === 'User not found' ||
          item.reason === 'Location not mapped'
      },
      {
        content: (
          <>
            {t('New Position Code')}
            <Icon name="pencil alternate" />
            &nbsp;
          </>
        ),
        onClick: (_: UserImportDto) => {
          this.onOpenNewPositionCodeModal();
        },
        isVisible: (item: UserImportDto) => item.reason === 'Position Code not found' || item.reason === 'Description Code not found'
      },
      {
        content: (
          <>
            {<span className="text__red">{t('Delete')}</span>}
            <Icon color="red" name="trash alternate" />
            &nbsp;
          </>
        ),
        onClick: (item: UserImportDto) => {
          this.setState({ selectedItem: item }, () => this.onShowDelete());
        }
      }
    ];

    return buttonMenuOptions;
  };

  private pruneInvalidUserImports = () => {
    this.setState({ pruning: true });
    this.props.userimportStore
      .pruneInvalidDuplicatedUserImports()
      .then(r => {
        this.setState({
          pruning: false,
          pruneResponse: r.data
        });
        if (r.data.isSuccess) {
          this.props.userimportStore.getAllAsync(this.state.query);
        }
      })
      .catch(error => {
        this.setState({
          pruning: false,
          pruneResponse:
            error?.response?.data?.messages != null
              ? error.response.data
              : {
                  isSuccess: false,
                  messages: [{ body: error.message || error || 'Unexpected Error', level: 'Error' }]
                }
        });
      });
  };

  private closePruningMessage = () => {
    this.setState({
      pruneResponse: null
    });
  };

  private closeUpdateUserMessage = () => {
    this.setState({
      updateUserResponse: null
    });
  };

  public render() {
    const { t } = this.props as any;
    const {
      activeFilters,
      filteredByMatched,
      pruning,
      pruneResponse,
      updateUserResponse,
      loading,
      someFilterOpened,
      userImportChangeReason
    } = this.state;

    const tableModel = {
      columns: [
        {
          title: t('Last Name'),
          tooltipRenderer: true,
          renderer: data => data.surname,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Last Name')}
              triggerTitle={title}
              onFilter={value => onFilter('surname', { surname: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('surname'))}
              active={activeFilters.includes(nameof<UserImportDto>('surname'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('surname'),
            useProfile: false
          }
        },
        {
          title: t('First Name'),
          tooltipRenderer: true,
          renderer: data => data.name,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by First Name')}
              triggerTitle={title}
              onFilter={value => onFilter('name', { name: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('name'))}
              active={activeFilters.includes(nameof<UserImportDto>('name'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('name'),
            useProfile: false
          }
        },
        {
          title: t('Email'),
          tooltipRenderer: true,
          renderer: data => data.email,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by email')}
              triggerTitle={title}
              onFilter={value => onFilter('email', { email: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('email'))}
              active={activeFilters.includes(nameof<UserImportDto>('email'))}
              onActivate={this.handleOnActivateFilter}
            />
          )
        },
        {
          title: t('Location'),
          tooltipRenderer: true,
          renderer: data => data.location,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Location')}
              triggerTitle={title}
              onFilter={value => onFilter('location', { location: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('location'))}
              active={activeFilters.includes(nameof<UserImportDto>('location'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('location'),
            useProfile: false
          }
        },
        {
          title: t('Personnel Area'),
          tooltipRenderer: true,
          renderer: data => data.personnelArea,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Personnel Area')}
              triggerTitle={title}
              onFilter={value => onFilter('personnelArea', { personnelArea: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('personnelArea'))}
              active={activeFilters.includes(nameof<UserImportDto>('personnelArea'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('personnelArea'),
            useProfile: false
          }
        },
        {
          title: t('Position Description'),
          tooltipRenderer: true,
          renderer: data => data.positionCode,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by Position Description')}
              triggerTitle={title}
              onFilter={value => onFilter('positionCode', { positionCode: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('positionCode'))}
              active={activeFilters.includes(nameof<UserImportDto>('positionCode'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('positionCode'),
            useProfile: false
          }
        },
        {
          title: t('SAP Position Title'),
          tooltipRenderer: true,
          renderer: data => data.positionTitle,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <TextBoxFilter
              filterTitle={t('Filter by SAP Position Title')}
              triggerTitle={title}
              onFilter={value => onFilter('positionTitle', { positionTitle: { contains: value } })}
              onClear={() => onClear(nameof<UserImportDto>('positionTitle'))}
              active={activeFilters.includes(nameof<UserImportDto>('positionTitle'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('positionTitle'),
            useProfile: false
          }
        },
        {
          title: t('Reasons'),
          tooltipRenderer: true,
          renderer: data => (data.reason === 'Description Code not found' ? 'Position Code not found' : data.reason),
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <OptionsFilter
              filterTitle={t('Filter by Reason')}
              options={[
                { text: t('New Personnel Area found'), value: 'New Personnel Area found' },
                { text: t('Location not mapped'), value: 'Location not mapped' },
                { text: t('User on hold by no import flag'), value: 'User on hold by no import flag' },
                { text: t('Duplicate User Import'), value: 'Duplicate User Import' },
                { text: t('Duplicate UserName'), value: 'Duplicate UserName' },
                { text: t('Incoming email already exists in database'), value: 'Incoming email already exists in database' },
                { text: t('Position Code not found'), value: 'Position Code not found' },
                { text: t('Employee/s suggested to update'), value: 'Employee/s suggested to update' },
                { text: t('User not found'), value: 'User not found' }
              ]}
              triggerTitle={title}
              onFilter={value =>
                onFilter(
                  nameof<UserImportDto>('reason'),
                  value === 'Position Code not found'
                    ? {
                        or: [
                          { [nameof<UserImportDto>('reason')]: 'Position Code not found' },
                          { [nameof<UserImportDto>('reason')]: 'Description Code not found' }
                        ]
                      }
                    : { [nameof<UserImportDto>('reason')]: value }
                )
              }
              onClear={() => onClear(nameof<UserImportDto>('reason'))}
              active={activeFilters.includes(nameof<UserImportDto>('reason'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('reason'),
            useProfile: false
          }
        },
        {
          title: t('Execution Timestamp'),
          tooltipRenderer: true,
          renderer: data => <>{DateTimeService.toDateInputString(DateTimeService.toString(data.createdOn))}</>,
          selectableHeader: true,
          headerRenderer: (title: string, onFilter, onClear) => (
            <DatesFilter
              filterTitle={t('Filter by Execution Timestamp')}
              triggerTitle={title}
              onFilter={(start, end) => {
                onFilter('createdOn', {
                  createdOn: {
                    ge: { value: start, type: 'raw' },
                    le: { value: end, type: 'raw' }
                  }
                });
              }}
              onClear={() => onClear(nameof<UserImportDto>('createdOn'))}
              active={activeFilters.includes(nameof<UserImportDto>('createdOn'))}
              onActivate={this.handleOnActivateFilter}
            />
          ),
          sortDefinition: {
            field: nameof<UserImportDto>('createdOn'),
            useProfile: false,
            active: 'Descending'
          }
        }
      ],
      data: this.props.userimportStore.state,
      showExpander: (item: UserImportDto) => (item?.matchRows || []).length !== 0,
      expandedRenderer: (item: UserImportDto) => {
        if (!item.matchRows) return <></>;
        return (
          <Table.Row className={'custom-table-expanded-row'}>
            <Table.Cell>
              <Form>
                {item.matchRows.map(row => {
                  return (
                    <Fragment key={row.id}>
                      <Checkbox
                        name={row.id}
                        value={row.id}
                        checked={this.state.selectedRow != null && this.state.selectedRow.id === row.id}
                        onChange={() => this.handleRowChange(row)}
                      />
                      <br />
                    </Fragment>
                  );
                })}
              </Form>
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.surname}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.name}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.email}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.location}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.personnelArea}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    {row.positionCode}
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
            <Table.Cell className="user-import__table__sub-row-style">
              {item.matchRows.map(row => {
                return (
                  <Fragment key={row.id}>
                    <br />
                  </Fragment>
                );
              })}
            </Table.Cell>
          </Table.Row>
        );
      }
    } as TableModel<UserImportDto>;
    return (
      <>
        {(pruning || loading) && (
          <Dimmer active={pruning || loading} page style={{ background: 'rgba(0, 0, 0, 0.4)' }}>
            <Loader indeterminate>{t('Processing')}</Loader>
          </Dimmer>
        )}

        <Grid className="event-types-list-grid">
          {this.props.userimportStore.state.result && !this.props.userimportStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.userimportStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          {this.props.changeUserStore.state.result && !this.props.changeUserStore.state.result.isSuccess && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className="error-message__style"
                icon="exclamation circle"
                error
                header={t('An error ocurred')}
                list={this.props.changeUserStore.state.result.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          {pruneResponse != null && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className={!pruneResponse.isSuccess && 'error-message__style'}
                icon={!pruneResponse.isSuccess && 'exclamation circle'}
                onDismiss={this.closePruningMessage}
                error={!pruneResponse.isSuccess}
                success={pruneResponse.isSuccess}
                header={t(pruneResponse.isSuccess ? 'Success!' : 'An error ocurred')}
                list={pruneResponse.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          {updateUserResponse != null && (
            <Grid.Row className="event-types-list-error-row">
              <Message
                className={!updateUserResponse.isSuccess && 'error-message__style'}
                icon={!updateUserResponse.isSuccess && 'exclamation circle'}
                onDismiss={this.closeUpdateUserMessage}
                error={!updateUserResponse.isSuccess}
                success={updateUserResponse.isSuccess}
                header={t(updateUserResponse.isSuccess ? 'Updated User!' : 'An error ocurred updating user')}
                list={updateUserResponse.messages.map(o => o.body)}
              />
            </Grid.Row>
          )}

          <Grid.Row className="event-types-list-items-row request-list__table-view">
            <TableView
              /////////////////For build table keyboard navegation/////////////////
              selectable={
                !this.state.showDeleteConfirm &&
                !this.state.isOpenedUsersModal &&
                !this.state.isOpenedNewPositionCodeModal &&
                !this.state.newUserShown
              }
              maxSelection={1}
              onHideCheckbox={true}
              selectionType={'allRow'}
              onEnterKeydown={this.handleOnEnterKeydown}
              onRowDoubleClick={this.handleOnRowDoubleClick}
              preventEnterKeyDownEvent={someFilterOpened}
              //showActionsConfirmModal={true}
              /////////////////For build table keyboard navegation/////////////////
              model={tableModel}
              onOrderByChanged={this.handleOrderBy}
              onRefresh={this.load}
              canEdit={false}
              canDelete={false}
              onPageChange={this.handlePageChange}
              onFilterChange={this.handleFilterChange}
              canCreateNew={false}
              extraActions={this.setActionsMenu()}
              alwaysExpanded={false}
              canExpandRows={true}
              extraMenu={[
                {
                  content: (
                    <Popup
                      position="top right"
                      flowing
                      trigger={
                        <Button
                          style={{ height: 34, width: 40 }}
                          icon={'trash'}
                          size="mini"
                          className="custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn"
                          onClick={this.pruneInvalidUserImports}
                        ></Button>
                      }
                    >
                      {t("Remove invalid 'Duplicate User Import' items")}
                    </Popup>
                  )
                },
                {
                  content: (
                    <Popup
                      position="top right"
                      trigger={
                        <Button
                          style={{ height: 34, width: 40 }}
                          className={`custom-table-upload-btn custom-table-upload-btn__up-down transparent-btn ${
                            filteredByMatched ? 'filtered-link-button' : 'inverted-color-btn'
                          }`}
                          onClick={this.onFilterMatched}
                          icon
                        >
                          <div className="user-import__matcher_btn_icon">=</div>
                        </Button>
                      }
                    >
                      {t('Toggle matched items')}
                    </Popup>
                  )
                }
              ]}
            />
          </Grid.Row>
        </Grid>

        <UserImportDeletePopup
          open={this.state.showDeleteConfirm}
          selectedItem={this.state.selectedItem}
          onCancelClicked={() => this.closeWindowModal()}
          onAcceptClicked={this.onDelete}
          userImportStore={this.props.userimportStore}
        />
        {this.state.isOpenedUsersModal && (
          <ChooseUserImportsView
            reason={userImportChangeReason}
            onAddParticipants={user => this.handleOnChangeUser(user)}
            onCloseUsersModal={() => this.setState({ isOpenedUsersModal: false })}
          />
        )}
        {this.state.isOpenedNewPositionCodeModal && <NewPositionCodeView onClose={this.onNewPositionCodeClosed} />}
        {this.state.newUserShown && <NewUserView onClose={this.onNewUserClosed} newUserFromImport={this.state.newUserFromImport} />}
        {this.state.editUserShown && (
          <ChangeUserView
            onClose={this.onEditUserClosed}
            currentUser={this.state.selectedLocationNotMappedUser}
            isUpdatingUserImportReason
            changeUserFromImport={this.state.changeUserFromImport}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(UserImportView);
